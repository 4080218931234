<template>
        <div class="white-overlay-loading" >

            <div tabindex="-1" class="white-overlay hard-block">

                <div class="spinner-container">

                    <div class="col-md-12 col-sm-12 col-xs-12">

                        <h5 tabindex="215" id="spinner-header" class="spinner-header">
                            Loading...</h5>

                        <div tabindex="216" title="10 blinking images indicating movement"
                            class="blue-spinner-progress spinnerBlue" style="width: 200px;">

                            <span class="spinner j-spinner" id="load_loop">
                                 
                                <img width="200" height="9" src="@/assets/blue-spinner-processing-step-01.svg"
                                    alt="spinner step 1" v-bind:class="{visible:i==1}" >
                                <img width="200" height="9" src="@/assets/blue-spinner-processing-step-02.svg"
                                    alt="spinner step 2" v-bind:class="{visible:i==2}">
                                <img width="200" height="9" src="@/assets/blue-spinner-processing-step-03.svg"
                                    alt="spinner step 3" v-bind:class="{visible:i==3}">
                                <img width="200" height="9" src="@/assets/blue-spinner-processing-step-04.svg"
                                    alt="spinner step 4" v-bind:class="{visible:i==4}">
                                <img width="200" height="9" src="@/assets/blue-spinner-processing-step-05.svg"
                                    alt="spinner step 5" v-bind:class="{visible:i==5}">
                                <img width="200" height="9" src="@/assets/blue-spinner-processing-step-06.svg"
                                    alt="spinner step 6" v-bind:class="{visible:i==6}">
                                <img width="200" height="9" src="@/assets/blue-spinner-processing-step-07.svg"
                                    alt="spinner step 7" v-bind:class="{visible:i==7}">
                                <img width="200" height="9" src="@/assets/blue-spinner-processing-step-08.svg"
                                    alt="spinner step 8" v-bind:class="{visible:i==8}">
                                <img width="200" height="9" src="@/assets/blue-spinner-processing-step-09.svg"
                                    alt="spinner step 9" v-bind:class="{visible:i==9}">
                                <img width="200" height="9" src="@/assets/blue-spinner-processing-step-10.svg"
                                    alt="spinner step 10" v-bind:class="{visible:i==10}">
                            </span>
                        </div>

                        <p tabindex="217" id="spinner-optional" class="spinner-optional">
                        </p>

                    </div>

                </div>

            </div>

        </div>
</template>

<script>
export default {
    name: 'Headr',
    created () {
        this.lop()
    },
    data() {
        return {
            i : 1,
        }
    },
    methods:{
        lop(){
            let ths = this
             this.i++
             if (this.i > 10) {
                this.i = 1
             }
             setTimeout(( )=>{
                ths.lop()
             },300)
        }
    }
}
</script>
 
<style scoped lang="scss"> 
</style>
