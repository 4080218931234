<template>
      <div aria-labelledby="dialogheader" aria-haspopup="true" class="white-spinner-wrapper">
                <div class="white-spinner-container" style="left: auto !important;">
                    <div class="spinner-content">
                        <h5 id="dialogheader" aria-modal="true" aria-label="Loading." role="alert" aria-live="rude">Loading</h5>
                        <div class="white-spinner-progress spinnerWhite"><span class="white-spinner">
                                <div class="spinner">
                                    <div class="bar-1"></div>
                                    <div class="bar-2"></div>
                                    <div class="bar-3"></div>
                                    <div class="bar-4"></div>
                                </div>
                            </span></div>
                        <p>Please wait while we process your request.</p>
                    </div>
                    <div class="gray-overlay"></div>
                </div>
            </div>
</template>

<script>
export default {
    name: 'tc',

}
</script>
 
<style scoped lang="scss">

.white-spinner-container {
    position: fixed;
    top: 41%;
    text-align: center;
    z-index: 999
}


.white-spinner-container .spinner-content {
    display: block;
    position: relative;
    background: #333366;
    opacity: 1;
    z-index: 1;
    padding: 30px
}

.white-spinner-container .spinner-content h5 {
    font-size: 18px;
    font-family: HelveticaNeueW02-75Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
    margin-top: 0;
    color: #fff;
    padding-bottom: 10px
}

.white-spinner-container {
    position: fixed;
    top: 41%;
    text-align: center;
    z-index: 99999
}

.white-spinner-container p {
    color: #fff;
    padding-top: 5px
}

.white-spinner-wrapper {
    margin: 36px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    vertical-align: middle
}

.white-spinner .spinner {
    text-align: center;
    font-size: 10px
}

.white-spinner .bar-1,
.white-spinner .bar-2,
.white-spinner .bar-3,
.white-spinner .bar-4,
.white-spinner .bar-5 {
    display: inline-block;
    background-color: #336;
    border: 1px solid #FFFFFF;
    width: 45px;
    height: 15px;
    opacity: 1;
    transform: skew(-9deg);
    -webkit-transform: skew(-9deg);
    -moz-transform: skew(-9deg);
    -o-transform: skew(-9deg);
    -webkit-animation: whitebar-stretchdelay 1.2s infinite ease-in-out;
    animation: whitebar-stretchdelay 1.2s infinite ease-in-out
}

.white-spinner .bar-2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s
}

.white-spinner .bar-3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s
}

.white-spinner .bar-4 {
    -webkit-animation-delay: -.9s;
    animation-delay: -.9s
}

.white-spinner .bar-5 {
    -webkit-animation-delay: -.8s;
    animation-delay: -.8s
}

@-webkit-keyframes whitebar-stretchdelay {

    0%,
    40%,
    to {
        background-color: #336
    }

    20% {
        background-color: #fff
    }
}

@keyframes whitebar-stretchdelay {

    0%,
    40%,
    to {
        background-color: #336
    }

    20% {
        background-color: #fff
    }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .white-spinner-wrapper {
        justify-content: flex-start;
        width: 320px !important
    }
}

@media only screen and (min-width: 768px) {
    .white-spinner-wrapper {
        width: 320px
    }
}

.gray-overlay {
    color: white;
    background-color: #031533;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .50
}
</style>
