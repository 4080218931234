<template>
     <div>
         <div id="global-footer--wrap" class="global-footer--wrap"><footer class="global-footer"><a href="https://www.usps.com/" class="global-footer--logo-link"></a> <nav class="global-footer--navigation"><ol><li class="global-footer--navigation-category" style="color: rgb(51, 51, 102);">
            Helpful Links
            <ol class="global-footer--navigation-options"><li><a href="https://www.usps.com/help/contact-us.htm">Contact Us</a></li> <li><a href="https://www.usps.com/globals/site-index.htm">Site Index</a></li> <li><a href="https://faq.usps.com/s/">FAQs</a></li> <li><a href="javascript:void(0);" onclick="KAMPYLE_ONSITE_SDK.showForm(244)">Feedback</a></li></ol></li> <li class="global-footer--navigation-category" style="color: rgb(51, 51, 102);">
            On About.USPS.com
            <ol class="global-footer--navigation-options"><li><a href="https://about.usps.com/">About USPS Home</a></li> <li><a href="https://about.usps.com/newsroom/">Newsroom</a></li> <li><a href="https://about.usps.com/newsroom/service-alerts/">USPS Service Updates</a></li> <li><a href="https://about.usps.com/resources/">Forms &amp; Publications</a></li> <li><a href="https://about.usps.com/what/government-services/">Government Services</a></li> <li><a href="https://about.usps.com/careers/">Careers</a></li></ol></li> <li class="global-footer--navigation-category" style="color: rgb(51, 51, 102);">
            Other USPS Sites
            <ol class="global-footer--navigation-options"><li><a href="https://gateway.usps.com/">Business Customer Gateway</a></li> <li><a href="https://www.uspis.gov/">Postal Inspectors</a></li> <li><a href="https://www.uspsoig.gov/">Inspector General</a></li> <li><a href="https://pe.usps.com/">Postal Explorer</a></li> <li><a href="https://postalmuseum.si.edu/">National Postal Museum</a></li> <li><a href="https://www.usps.com/business/web-tools-apis/">Resources for Developers</a></li></ol></li> <li class="global-footer--navigation-category" style="color: rgb(51, 51, 102);">
            Legal Information
            <ol class="global-footer--navigation-options"><li><a href="https://about.usps.com/who/legal/privacy-policy/">Privacy Policy</a></li> <li><a href="https://about.usps.com/who/legal/terms-of-use.htm">Terms of Use</a></li> <li><a href="https://about.usps.com/who/legal/foia/">FOIA</a></li> <li><a href="https://about.usps.com/who/legal/no-fear-act/">No FEAR Act/EEO Contacts</a></li></ol></li></ol></nav> <div class="global-footer--copyright">Copyright © 2023 USPS. All Rights Reserved. </div> <ul class="global-footer--social"><li><a href="https://www.facebook.com/USPS?rf=108501355848630" style="text-decoration: none;"><img alt="Image of Facebook social media icon." src="@/static/images/social-facebook_1.png"></a></li> <li><a href="https://twitter.com/usps" style="text-decoration: none;"><img alt="Image of Twitter social media icon." src="@/static/images/social-twitter_2.png"></a></li> <li><a href="http://www.pinterest.com/uspsstamps/" style="text-decoration: none;"><img alt="Image of Pinterest social media icon." src="@/static/images/social-pinterest_6.png"></a></li> <li><a href="https://www.youtube.com/usps" style="text-decoration: none;"><img alt="Image of Youtube social media icon." src="@/static/images/social-youtube_3.png"></a></li></ul></footer></div>

     </div>
</template>

<script>
export default {
    name: 'Headr',

}
</script>
 
<style scoped lang="scss">

.t6 {
    background: #fff;

   
    .t62 {
         background: #fff;
        padding-top: 20px;
        height: 34px;
        border-bottom: 4px solid #336;
       
    }
    .t63 {
        height: 50px;
    }

}

.t7 {
    background: #fff;
    padding: 15px;
    text-align: center;
    padding-bottom: 30px;

    h2 {
        margin-bottom: 20px;
        font-size: 20px;
    }
    p {
        margin: auto;
        width: 80%;
        display: block;
    }
    .button {
        font-weight: 700;
        display: inline-block;
        text-align: center;

        height: 44px;
        line-height: 44px;
        font-size: 16px;
        border-radius: 3px;
        padding: 0 20px;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;

        margin-top: 30px;

        background: #fff;
        color: #336;
        border: 1px solid #336;
    }

}

.t8 {
    padding-top: 20px;

    .global-footer--logo-link {
        background: transparent url(@/static/images/footer-logo.png) top left;
        display: inline-block;
        height: 27px;
        width: 221px;
    }

    .global-footer--navigation {
        margin: 30px 0 0;
    }

    .global-footer--navigation-category {

        color: #333366;
        display: inline-block;
        font-size: 11px;
        font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial, sans-serif;
        line-height: 20px;
        margin: 0 0 30px;
        text-transform: uppercase;
        vertical-align: top;
        width: 24.4%;
        width: 43vw;
        font-weight: bold;

    }

    li,
    ol {
        list-style: none;
        text-transform: none;
        margin: 0;
        list-style: none;
        margin: 0px;
        padding: 0px;
    }

    a {
        color: #202020;
        font-family: "HelveticaNeueW02-65Medi", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 11px;
        font-weight: normal;
        line-height: 20px;
        text-decoration: none;
    }

    .global-footer--copyright {
        border-bottom: 1px solid #dadada;
        font-size: 11px;
        padding: 0 0 30px;
    }

    .global-footer--social {
        padding-bottom: 30px;
        margin-top:30px;
        width: 80%;
        display: flex;
        align-items: center;
        justify-content:flex-start;
        // height: 80px;
        text-align: left;
        div {
            display: inline-block;
            padding-right: 20px;
            // margin-left: -10px;
            

            img {
                width: 50px;
                height: auto;
            }
        }
    }
}

</style>
